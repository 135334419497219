import React, { Component } from 'react';
// import { Link } from 'react-router-dom';
import FeatureIcon from '../components/FeatureIcon';
// import Funfact from '../components/Funfact';
// import TeamMemberGrid from '../components/TeamMemberGrid';
// import TestimonialSlider from '../components/TestimonialSlider';
import BrandLogoSlider from '../components/BrandLogoSlider';
// import AboutGallery1 from './components/AboutGallery1';

// import ModalVideo from 'react-modal-video';
class About extends Component {
    constructor() {
        super();
        this.state = {
            isOpen: false,
        };
        this.openModal = this.openModal.bind(this);
    }

    openModal() {
        this.setState({ isOpen: true });
    }

    render() {
        return (
            <div>
                {/* breadcrumb */}
                {/*====================  breadcrumb area ====================*/}
                <div className='breadcrumb-area services-bg'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col'>
                                <div className='page-banner text-center'>
                                    <h1>Privacy Policy</h1>
                                    <ul className='page-breadcrumb'></ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/*====================  End of breadcrumb area  ====================*/}

                <div className='page-wrapper section-space--inner--top--50'>
                    {/*About section start*/}
                    <div className='about-section section-space--inner--bottom--50'>
                        <div className='container'>
                            <div className='row row-25 align-items-center'>
                                {/* <div className="col-lg-6 col-12 mb-30"> !!!!!!!!!!!!!!!!!! video if needed in future (change colume values of below div to: 'col-lg-6 col-12 mb-30')
                                    <div className="about-image-two">
                                        <img src="assets/img/about/about-3.jpg" alt="" />
                                        <span className="video-popup">
                                            <ModalVideo channel="youtube" isOpen={this.state.isOpen} videoId="OrS-93U4AYQ" onClose={() => this.setState({ isOpen: false })} />
                                            <button onClick={this.openModal}>
                                                <i className="ion-ios-play-outline" />
                                            </button>
                                        </span>
                                    </div>
                                </div> */}
                                <div className='col-lg-12 col-12 mb-30'>
                                    <div className='about-content-two'>
                                        <h3>SL Technology LLC Website Privacy Policy </h3>
                                        <h4>Last updated: 7/6/2020</h4>
                                        <p>
                                            SL Technology LLC ("us", "we", or "our") operates http://www.sltechnology.net (the "Site"). This page informs you of our policies regarding the collection,
                                            use and disclosure of Personal Information we receive from users of the Site.
                                        </p>
                                        <p>
                                            We use your Personal Information only for providing and improving the Site. By using the Site, you agree to the collection and use of information in
                                            accordance with this policy.
                                        </p>
                                        <h4>Information Collection And Use</h4>
                                        <p>
                                            While using our Site, we may ask you to provide us with certain personally identifiable information that can be used to contact or identify you. Personally
                                            identifiable information may include, but is not limited to your name ("Personal Information").{' '}
                                        </p>
                                        <h4>Log Data</h4>
                                        <p>
                                            Like many site operators, we collect information that your browser sends whenever you visit our Site ("Log Data"). This Log Data may include information
                                            such as your computer's Internet Protocol ("IP") address, browser type, browser version, the pages of our Site that you visit, the time and date of your
                                            visit, the time spent on those pages and other statistics. In addition, we may use third party services such as Google Analytics that collect, monitor and
                                            analyze this data.
                                        </p>
                                        <h4>Communications</h4>
                                        <p>
                                            We may use your Personal Information to contact you with newsletters, marketing or promotional materials and other information. If you do not wish for us to
                                            use your Personal Information, do not input it on this website.{' '}
                                        </p>
                                        <h4>Cookies</h4>
                                        <p>
                                            Cookies are files with small amount of data, which may include an anonymous unique identifier. Cookies are sent to your browser from a web site and stored
                                            on your computer's hard drive.{' '}
                                        </p>
                                        <p>
                                            Like many sites, we use "cookies" to collect information. You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent.
                                            However, if you do not accept cookies, you may not be able to use some portions of our Site.{' '}
                                        </p>
                                        <h4>Security</h4>
                                        <p>
                                            The security of your Personal Information is important to us, but remember that no method of transmission over the Internet, or method of electronic
                                            storage, is 100% secure. While we strive to use commercially acceptable means to protect your Personal Information, we cannot guarantee its absolute
                                            security.
                                        </p>
                                        <h4>Changes To This Privacy Policy</h4>
                                        <p>
                                            This Privacy Policy is effective as of July 1, 2020 and will remain in effect except with respect to any changes in its provisions in the future, which will
                                            be in effect immediately after being posted on this page.{' '}
                                        </p>
                                        <p>
                                            We reserve the right to update or change our Privacy Policy at any time and you should check this Privacy Policy periodically. Your continued use of the
                                            Service after we post any modifications to the Privacy Policy on this page will constitute your acknowledgment of the modifications and your consent to
                                            abide and be bound by the modified Privacy Policy.{' '}
                                        </p>
                                        <p>
                                            If we make any material changes to this Privacy Policy, we will notify you either through the email address you have provided us, or by placing a prominent
                                            notice on our website.{' '}
                                        </p>
                                        <h4>Contact Us</h4>
                                        <p>If you have any questions about this Privacy Policy, please <a href="contact-us"><u>contact us</u></a>. </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/*About section end*/}

                    {/* Feature Icon */}
                    <FeatureIcon background='grey-bg' />

                    {/* Fun fact */}
                    {/* <Funfact /> */}

                    {/* Team member */}

                    {/* Testimonial Slider */}
                    {/* <TestimonialSlider /> */}

                    {/* Brand logo */}
                    <BrandLogoSlider background='' />
                </div>

                {/* Footer */}
                {/* <Footer /> */}
            </div>
        );
    }
}

export default About;
