import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class Footer extends Component {
  render() {
    return (
      <div>
        {/*====================  footer area ====================*/}
        <div className='footer-area dark-bg'>
          <div className='container'>
            <div className='row'>
              <div className='col-lg-12'>
                <div className='footer-content-wrapper section-space--inner--50'>
                  <div className='row'>
                    <div className='col-xl-3 col-lg-3 col-md-12'>
                      {/* footer intro wrapper */}
                      <div className='footer-intro-wrapper'>
                        <div className='footer-logo'>
                          <Link to={`${process.env.PUBLIC_URL}/`}>
                            <img src='assets/img/logo/logo-light.png' className='img-fluid' alt='' />
                          </Link>
                        </div>
                        <div className='footer-desc'>
                          We'd love to discuss our services and offerings with you; contact us today.
                        </div>
                      </div>
                    </div>
                    <div className='col-xl-2 offset-xl-1 col-lg-3 col-md-4'>
                      {/* footer widget */}
                      <div className='footer-widget'>
                        <h4 className='footer-widget__title'>Useful Links</h4>
                        <ul className='footer-widget__navigation'>
                          <li>
                            <Link to={`${process.env.PUBLIC_URL}/`}>Home</Link>
                          </li>
                          <li>
                            <Link to={`${process.env.PUBLIC_URL}/services`}>Services</Link>
                          </li>
                          <li>
                            <Link to={`${process.env.PUBLIC_URL}/solutions`}>Solutions For...</Link>
                          </li>
                          <li>
                            <Link to={`${process.env.PUBLIC_URL}/about-us`}>About Us</Link>
                          </li>
                          <li>
                            <Link to={`${process.env.PUBLIC_URL}/contact-us`}>Contact Us</Link>
                          </li>
                          <li>
                            <Link to={`${process.env.PUBLIC_URL}/privacy-policy`}>
                              Privacy Policy
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className='col-xl-2 offset-xl-1 col-lg-3 col-md-4'>
                      {/* footer widget */}
                      <div className='footer-widget'>
                        <h4 className='footer-widget__title'>Services</h4>
                        <ul className='footer-widget__navigation'>
                          <li>
                            <Link to={`${process.env.PUBLIC_URL}/services/it-support`}>IT Support</Link>
                          </li>
                          <li>
                            <Link to={`${process.env.PUBLIC_URL}/services/audiovisual`}>Audiovisual Services</Link>
                          </li>
                          <li>
                            <Link to={`${process.env.PUBLIC_URL}/services/video-surveillance`}>Video Surveillance</Link>
                          </li>
                          <li>
                            <Link to={`${process.env.PUBLIC_URL}/services/software-development`}>
                              Software Development
                            </Link>
                          </li>
                          
                        </ul>
                      </div>
                    </div>
                    <div className='col-xl-2 offset-xl-1 col-lg-3 col-md-4'>
                      {/* footer widget */}
                      <div className='footer-widget mb-0'>
                        <h4 className='footer-widget__title'>Contact Us</h4>
                        <div className='footer-widget__content'>
                          <p className='address'>
                            <a href="https://goo.gl/maps/4YzbaefKHtTLJPxV6" target="_blank">
                              912 W Main St #403, New Holland, PA 17557
                            </a>
                          </p>
                          <ul className='contact-details'>
                            <li>
                              <a href="tel:7176290662" >(717) 629-0662</a>
                            </li>
                            <li>
                              <a href="mailto:info@sltechnology.net">info@sltechnology.net</a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='footer-copyright-wrapper'>
            <div className='footer-copyright text-center'>© {new Date().getFullYear()} SL Technology LLC</div>
          </div>
        </div>
        {/*====================  End of footer area  ====================*/}
        {/*====================  scroll top ====================*/}
        <button className='scroll-top' id='scroll-top'>
          <i className='ion-android-arrow-up' />
        </button>
        {/*====================  End of scroll top  ====================*/}
      </div>
    );
  }
}

export default Footer;
