import React, { Component } from 'react';

class ComputerSales extends Component {
  render() {
    return (
      <div className='col-12 section-space--top--30'>
        <div className='service-grid-item service-grid-item--style2'>
          {/* <div className='service-grid-item__image'> ## image above box
            <div className='service-grid-item__image-wrapper'>
              <img src={`/assets/img/service/serviceShort.jpg`} className='img-fluid' alt='Service Grid' />
            </div>
          </div> */}
          <div name='Computer Sales' className='service-grid-item__content'>
            <h3 className='title'>
              <img width={50} alt={'icon'} style={{ paddingRight: '10px' }} src='/assets/img/icons/ComputerSales.png' />
              Computer Sales
            </h3>
            <p className='subtitle'>
              Is a new computer in your future? We sell Dell’s business line of quality computers including OptiPlex
              desktops and Latitude laptops. We also sell refurbished desktop and laptops. We do not sell low quality
              computers often found at big box stores.
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default ComputerSales;
