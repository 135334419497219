import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import MobileMenu from './MobileMenu';

class NavBar extends Component {
  constructor(props) {
    super(props);
    this.MobileMenuElement = React.createRef();
    this.state = {
      activePage: 'home',
    };
  }

  handleClickMobileMenu = () => {
    this.MobileMenuElement.current.changeState();
  };

  render() {
    return (
      <div>
        {/*====================  header area ====================*/}
        <div className='header-area'>
          <div className='header-area__desktop header-area__desktop--default'>
            {/*=======  header top bar  =======*/}
            <div className='header-top-bar'>
              <div className='container'>
                <div className='row align-items-center'>
                  <div className='col-lg-4'>
                    {/* top bar left */}
                    {/* <div className="top-bar-left-wrapper">
                                            <div className="social-links social-links--white-topbar d-inline-block">
                                                <ul>
                                                    <li>
                                                        <Link to="//facebook.com">
                                                            <i className="zmdi zmdi-facebook" />
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link to="//twitter.com">
                                                            <i className="zmdi zmdi-twitter" />
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link to="//vimeo.com">
                                                            <i className="zmdi zmdi-vimeo" />
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link to="//linkedin.com">
                                                            <i className="zmdi zmdi-linkedin-box" />
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link to="//skype.com">
                                                            <i className="zmdi zmdi-skype" />
                                                        </Link>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div> */}
                  </div>
                  <div className='col-lg-8'>
                    {/* top bar right */}
                    <div className='top-bar-right-wrapper'>
                      <Link to={`${process.env.PUBLIC_URL}/support`} className='ht-btn ht-btn--default d-inline-block'>
                        GET HELP
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/*=======  End of header top bar  =======*/}
            {/*=======  header info area  =======*/}
            <div className='header-info-area'>
              <div className='container'>
                <div className='row align-items-center'>
                  <div className='col-lg-12'>
                    <div className='header-info-wrapper align-items-center'>
                      {/* logo */}
                      <div className='logo'>
                        {/* <Link to={`${process.env.PUBLIC_URL}/`}> */}
                        <Link to={`${process.env.PUBLIC_URL}/`}>
                          <img src='/assets/img/logo/logo.png' className='img-fluid' alt='Logo' />
                        </Link>
                        {/* </Link> */}
                      </div>
                      {/* header contact info */}
                      <div className='header-contact-info'>
                        <div className='header-info-single-item'>
                          <div className='header-info-single-item__icon'>
                            <i className='zmdi zmdi-smartphone-android' />
                          </div>
                          <div className='header-info-single-item__content'>
                            <h6 className='header-info-single-item__title'>Phone</h6>
                            <p className='header-info-single-item__subtitle'>
                              <a href="tel:7176290662">
                                (717) 629-0662
                              </a>
                            </p>
                          </div>
                        </div>
                        <div className='header-info-single-item'>
                          <div className='header-info-single-item__icon'>
                            <i className='zmdi zmdi-home' />
                          </div>
                          <div className='header-info-single-item__content'>
                            <h6 className='header-info-single-item__title'>Address</h6>
                            <p className='header-info-single-item__subtitle'>
                              <a href="https://goo.gl/maps/4YzbaefKHtTLJPxV6" target="_blank">
                                912 W Main St #403, New Holland, PA 17557
                              </a>
                            </p>
                          </div>
                        </div>
                      </div>

                      <div
                        onClick={this.handleClickMobileMenu}
                        className='mobile-navigation-icon'
                        id='mobile-menu-trigger'
                      >
                        <i />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/*=======  End of header info area =======*/}
            {/*=======  header navigation area  =======*/}
            <div className='header-navigation-area default-bg'>
              <div className='container'>
                <div className='row'>
                  <div className='col-lg-12'>
                    {/* header navigation */}
                    <div className='header-navigation header-navigation--header-default position-relative'>
                      <div className='header-navigation__nav position-static'>
                        <nav>
                          <ul>
                            <li>
                              <Link to={`${process.env.PUBLIC_URL}/`}>Home</Link>
                            </li>
                            <li id='ServicesTab' className='has-children has-children--multilevel-submenu'>
                              <Link to={`${process.env.PUBLIC_URL}/services`}>Services</Link>
                              <ul className='submenu'>
                                <li>
                                  <Link to={`${process.env.PUBLIC_URL}/services/it-support`}>IT Support</Link>
                                </li>
                                <li id='AudioVisualTab' className='has-children has-children--multilevel-submenu'>
                                  <Link to={`${process.env.PUBLIC_URL}/services/audiovisual`}>Audiovisual</Link>
                                  <ul className='submenu'>
                                  <li>
                                      <Link to={`${process.env.PUBLIC_URL}/services/audiovisual/integration`}>Integration</Link>
                                    </li>
                                    <li>
                                      <Link to={`${process.env.PUBLIC_URL}/services/audiovisual/rental`}>Equipment Rental</Link>
                                    </li>
                                    <li>
                                      <Link to={`${process.env.PUBLIC_URL}/services/audiovisual/sales`}>Equipment Sales</Link>
                                    </li>
                                  </ul>
                                </li>
                                <li>
                                  <Link to={`${process.env.PUBLIC_URL}/services/video-surveillance`}>
                                    Video Surveillance
                                  </Link>
                                </li>
                                <li>
                                  <Link to={`${process.env.PUBLIC_URL}/services/software-development`}>
                                    Software Development
                                  </Link>
                                </li>
                              </ul>
                            </li>
                            <li id='SolutionsTab' className='has-children has-children--multilevel-submenu'>
                              <Link to={`${process.env.PUBLIC_URL}/solutions`}>Solutions For...</Link>
                              <ul className='submenu'>
                                <li>
                                  <Link to={`${process.env.PUBLIC_URL}/solutions/small-business`}>Small Business</Link>
                                </li>
                                <li>
                                  <Link to={`${process.env.PUBLIC_URL}/solutions/education`}>Education</Link>
                                </li>
                                <li>
                                  <Link to={`${process.env.PUBLIC_URL}/solutions/worship`}>House of Worship</Link>
                                </li>
                              </ul>
                            </li>
                            <li className={this.state.activePage.includes('about-us') ? 'active' : ''}>
                              <Link to={`${process.env.PUBLIC_URL}/about-us`}>About Us</Link>
                            </li>
                            <li className={this.state.activePage.includes('contact-us') ? 'active' : ''}>
                              <Link to={`${process.env.PUBLIC_URL}/contact-us`}>Contact Us</Link>
                            </li>
                          </ul>
                        </nav>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/*=======  End of header navigation area =======*/}
          </div>
        </div>
        {/*====================  End of header area  ====================*/}

        <MobileMenu ref={this.MobileMenuElement} />
      </div>
    );
  }
}

export default withRouter(NavBar);
