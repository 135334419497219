import React, { Component } from 'react';

class RecordingSystems extends Component {
  render() {
    return (
      <div className='col-12 section-space--top--30'>
        <div className='service-grid-item service-grid-item--style2'>
          {/* <div className='service-grid-item__image'> ## image above box
            <div className='service-grid-item__image-wrapper'>
              <img src={`/assets/img/service/serviceShort.jpg`} className='img-fluid' alt='Service Grid' />
            </div>
          </div> */}
          <div name='Recording Systems' className='service-grid-item__content'>
            <h3 className='title'>
              <img
                width={50}
                alt={'icon'}
                style={{ paddingRight: '10px' }}
                src='/assets/img/icons/recording-video.png'
              />
              Recording Systems
            </h3>
            <p className='subtitle'>
              We install traditional fixed channel hardware-based DVR/NVR solutions as well as flexible and powerful
              software based solutions from Milestone.
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default RecordingSystems;
