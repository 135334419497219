import React, { Component } from 'react';

class AVManufacturers extends Component {
  render() {
    return (
      <div className='col-12 section-space--top--30'>
        <div className='service-grid-item service-grid-item--style2'>
          {/* <div className='service-grid-item__image'> ## image above box
            <div className='service-grid-item__image-wrapper'>
              <img src={`/assets/img/service/serviceShort.jpg`} className='img-fluid' alt='Service Grid' />
            </div>
          </div> */}
          <div name='Manufacturers' className='service-grid-item__content'>
            <h3 className='title'>
              <img width={50} alt={'icon'} style={{ paddingRight: '10px' }} src='/assets/img/icons/manufacturers.png' />
              Manufacturers
            </h3>
            <p className='subtitle'>
              We have access to all the top AV manufacturers, and more, offering top quality equipment including laser projectors from NEC and audio solutions from JBL,
              Shure and Atlas. Contact us today to discuss your needs.
            </p>
            <a
              href="mailto:avsales@sltechnology.net"
              target='BLANK'
              className='ht-btn--default ht-btn--default--dark-hovers'
            >
              CONTACT US
            </a>
          </div>
        </div>
      </div>
    );
  }
}

export default AVManufacturers;
