import React, { Component } from 'react';
import { Link } from 'react-router-dom';
// import base64 from 'react-native-base64';
// import FeatureIcon from '../components/FeatureIcon';
// // import Funfact from '../components/Funfact';
// import TeamMemberGrid from '../components/TeamMemberGrid';
// import TestimonialSlider from '../components/TestimonialSlider';
import BrandLogoSlider from '../components/BrandLogoSlider';

// MS Azure Authentication
// import { AzureAD, AuthenticationState } from 'react-aad-msal';
// import { authProvider } from '../authProvider';

// import ModalVideo from 'react-modal-video';
class Support extends Component {
	constructor() {
		super();
		this.state = {
			isOpen: false,
			password: '',
		};
		this.openModal = this.openModal.bind(this);
	}

	openModal() {
		this.setState({ isOpen: true });
	}

	render() {
		let data = [
			{
				pageLink: '/downloads/TeamViewer Host',
				img: 'TeamViewer-Host.jpg',
				projectTitle: 'TeamViewer Host',
				newTab: false,
			},
			{
				pageLink: '/downloads/SL Software',
				img: 'SL-Software.jpg',
				projectTitle: 'SL Software',
				newTab: false,
			},
			{
				pageLink: 'https://unms.sltechnology.net/',
				img: 'UNMS.jpg',
				projectTitle: 'UNMS',
				newTab: true,
			},
			{
				pageLink: 'https://apps.spiceworks.com/tools/device-inventory/',
				img: 'Spiceworks.jpg',
				projectTitle: 'Spiceworks',
				newTab: true,
			},
			{
				pageLink: 'https://partners.avast.com/s/',
				img: 'Avast-Pro-Antivirus.jpg',
				projectTitle: 'Avast Business Partner',
				newTab: true,
			},
			{
				pageLink: 'https://sltechnology.sharepoint.com/SitePages/Home.aspx',
				img: 'SL-SharePoint.jpg',
				projectTitle: 'SL SharePoint',
				newTab: true,
			},
			{
				pageLink: 'https://www.dellemc.com/partner/en-us/auth/partner-portal.htm',
				img: 'Dell-Partner.jpg',
				projectTitle: 'Dell Partner Portal',
				newTab: true,
			},
			{
				pageLink: 'http://www.dell.com/premier/us/en/RC1369743/shop?~ck=mn#/systems?-ck=mn',
				img: 'Dell-Premier.jpg',
				projectTitle: 'Dell Premier',
				newTab: true,
			},
			{
				pageLink: 'https://www.scansource.com/en/shop',
				img: 'ScanSource.jpg',
				projectTitle: 'ScanSource ',
				newTab: true,
			},
			{
				pageLink: 'https://access.almo.com/products.asp',
				img: 'Almo.jpg',
				projectTitle: 'Almo',
				newTab: true,
			},
			{
				pageLink: 'https://us.cloud.im/?catid=67',
				img: 'Ingram-Micro.jpg',
				projectTitle: 'Ingram Micro Cloud',
				newTab: true,
			},
		];

		let Datalist = data.map((val, i) => {
			return (
				<div className='col-lg-4 col-sm-6 col-12 section-space--bottom--30' key={i}>
					<div className='service-grid-item service-grid-item--style2'>
						<div className='service-grid-item__image'>
							<div className='service-grid-item__image-wrapper'>
								<a target={val.newTab ? '_blank' : '_top'} rel='noopener noreferrer' href={`${val.pageLink}`}>
									<img src={`assets/img/support/${val.img}`} className='img-fluid' alt='' />
								</a>
							</div>
						</div>
						<div className='service-grid-item__content'>
							<h3 className='title'>
								<a href={`${val.pageLink}`}>{val.projectTitle}</a>
							</h3>
						</div>
					</div>
				</div>
			);
		});

		return (
			<div>
				{/* breadcrumb */}
				{/*====================  breadcrumb area ====================*/}
				<div className='breadcrumb-area support-bg'>
					<div className='container'>
						<div className='row'>
							<div className='col'>
								<div className='page-banner text-center'>
									<h1>Technician</h1>
									<ul className='page-breadcrumb'>
										<li>
											<Link to='/'>Home</Link>
										</li>
										<li>Technician</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
				{/*====================  End of breadcrumb area  ====================*/}

				<div className='page-wrapper section-space--inner--top--30'>
					{/*Suppport section start*/}
					<div className='about-section section-space--inner--bottom--30'>
						<div className='container'>
							<div className='row row-25 align-items-center'>
								<div className='col-lg-12 col-12 mb-30'>
									<div className='about-content-two'>
										<h3>Internal links</h3>{' '}
										<div style={this.state.showMyComponent ? { display: 'none' } : {}} className='contact-form'>
											<div className='row row-10'>
												<div className='col-12'>
													<p>
														<span>Hello!</span>
														<br />
														<br />
														<br /> {/*====================  support page content ====================*/}
														<div className='project-item-wrapper'>
															<div className='row'>{Datalist}</div>
														</div>
													</p>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					{/*Suppport section end*/}

					{/* Brand logo */}
					<BrandLogoSlider background='' />
				</div>
			</div>
		);
	}
	s;
}

export default Support;
