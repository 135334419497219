import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import Reaptcha from 'reaptcha';
// email api

class Contact extends Component {
  constructor(props) {
    super(props);
    this.state = {

      verified: false,
      //New
      feedback: '',
      name: 'Name',
      email: 'email@example.com',
      
      //old
      contactName: '',
      contactEmail: '',
      contactPhone: '',
      contactSubject: '',
      contactMessage: '',
      error: '',
      errorColor: 'red',
      sent: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  onVerify = () => {
    this.setState({
      verified: true,
    });
  };

  handleEmailFormSubmit = () => {
    const validEmailRegex = RegExp(
      /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i
    );

    if (
      this.state.contactName.trim().length === 0 ||
      this.state.contactEmail.trim().length === 0 ||
      this.state.contactSubject.trim().length === 0 ||
      this.state.contactMessage.trim().length === 0
    ) {
      this.setState({ error: 'Please complete all required fields.' });
    } else if (!validEmailRegex.test(this.state.contactEmail)) {
      this.setState({ error: 'Email is invalid.' });
    } else {
      //this.sendEmail();
      this.handleSubmit();
    }
  };


  handleChange(event){
    this.setState({feedback: event.target.value});
  }
  handleSubmit(event){
    const templateId = 'template_id';
    this.sendFeedback(templateId, {message_html: this.state.feedback, from_name: this.state.name, reply_to: this.state.email})
  }

  // old function to send emails.
  sendEmail() {
      
    
    /*
    var data = JSON.stringify({
      "subject": this.state.contactSubject,
      "issue": this.state.contactName + "<br>" + this.state.contactEmail + "<br>" + this.state.contactPhone + "<br><br>" + this.state.contactMessage,
      "owner": "5ee2982473ef0e0011f8fb13",
      "group": "5ef3801939a5810011bd427f",
      "type": "5ee2982473ef0e0011f8fb0d",
      "priority": "5ee29828ae3add00213d805e"
    });
    
    var xhr = new XMLHttpRequest();
    xhr.withCredentials = true;
    
    xhr.addEventListener("readystatechange", function () {
      if (this.readyState === this.DONE) {
        console.log(this.responseText);
      }
    });
    
    This code snippet previously sent all contact form submissions to trudesk. As of 9/28/22 Trudesk has been suspended as service

    xhr.open("POST", "https://support.sltechnology.net/api/v1/tickets/create");
    xhr.setRequestHeader("accesstoken", "02f22fa3bffca641aebe11f98bdd0fe2f079a671");
    xhr.setRequestHeader("content-type", "application/json");
    xhr.withCredentials = false;
    xhr.send(data);
    
    xhr.open();
    xhr.sendEmail();
    */
    this.setState({ error: 'Inquiry has been sent.', errorColor: 'green' });
    this.setState({
      contactEmail: '',
      contactName: '',
      contactPhone: '',
      contactSubject: '',
      contactMessage: '',
      verified: false,
      sent: true,
    });
  }

  render() {
    return (
      
      <div>
        {/* breadcrumb */}
        {/*====================  breadcrumb area ====================*/}
        <div className='breadcrumb-area contact-bg'>
          <div className='container'>
            <div className='row'>
              <div className='col'>
                <div className='page-banner text-center'>
                  <h1>Contact Us</h1>
                  <ul className='page-breadcrumb'>
                    <li>
                      <Link to='/'>Home</Link>
                    </li>
                    <li>Contact Us</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*====================  End of breadcrumb area  ====================*/}

        {/*====================  content page content ====================*/}
        
        <div className='page-wrapper section-space--inner--120'>
          {/*Contact section start*/}
          <div className='conact-section'>
            <div className='container'>
              {/* <div className="row section-space--bottom--50">
                                <div className="col">
                                    <div className="contact-map">
                                        <iframe
                                            title="google-map"
                                            src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d39290.2004030554!2d-76.12162147646829!3d40.0913589007123!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sbd!4v1558429398879!5m2!1sen!2sbd"
                                            allowFullScreen
                                        ></iframe>
                                    </div>
                                </div>
                            </div> */}
              <div className='row'>
                <div className='col-lg-4 col-12'>
                  <div className='contact-information'>
                    <h3>Contact Information</h3>
                    <ul>
                      <li>
                        <span className='icon'>
                          <i className='ion-android-map' />
                        </span>
                        <span className='text'>
                          <span>
                            <a href="https://goo.gl/maps/adHFMynW15UFKq9b9" target="_blank">
                              912 W Main St #403
                              <br />
                              New Holland, PA 17557
                            </a>
                          </span>
                        </span>
                      </li>
                      <li>
                        <span className='icon'>
                          <i className='ion-ios-telephone-outline' />
                        </span>
                        <span className='text'>
                          <a href='tel:7176290662'>(717) 629-0662</a>
                        </span>
                      </li>
                      <li>
                        <span className='icon'>
                          <i className='ion-ios-email-outline' />
                        </span>
                        <span className='text'>
                          <a href='mailto:info@sltechnology.net'>info@sltechnology.net</a>
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>

                
                <div className='col-lg-8 col-12'>
                  <div className='contact-form'>
                    <h3>Send us a message</h3>
                    <div className='row row-10'>
                      <div className='col-md-6 col-12 section-space--bottom--20'>
                        <input
                          name='con_name'
                          type='text'
                          placeholder='Your Name'
                          value={this.state.contactName}
                          onChange={(e) => this.setState({ contactName: e.target.value })}
                        />
                      </div>
                      <div className='col-md-6 col-12 section-space--bottom--20'>
                        <input
                          name='con_email'
                          type='email'
                          placeholder='Your Email'
                          value={this.state.contactEmail}
                          onChange={(e) => this.setState({ contactEmail: e.target.value })}
                        />
                      </div>
                      <div className='col-12 section-space--bottom--20'>
                        <input
                          name='con_phone'
                          type='phone'
                          placeholder='Your Phone'
                          value={this.state.contactPhone}
                          onChange={(e) => this.setState({ contactPhone: e.target.value })}
                        />
                      </div>
                      <div className='col-12 section-space--bottom--20'>
                        <input
                          name='con_subject'
                          type='text'
                          placeholder='Subject'
                          value={this.state.contactSubject}
                          onChange={(e) => this.setState({ contactSubject: e.target.value })}
                        />
                      </div>
                      <div className='col-12'>
                        <textarea
                          name='con_message'
                          placeholder='Your Message'
                          value={this.state.contactMessage}
                          onChange={(e) => this.setState({ contactMessage: e.target.value })}
                        />

                        
                      </div>
                      <div className='col-12'>
                        <span style={{ color: this.state.errorColor }}>{this.state.error}</span>
                      </div>
                      <div
                        style={this.state.verified || this.state.sent ? { display: 'none' } : {}}
                        className='col-md-6 col-12'
                      >
                        <Reaptcha sitekey='6Lf0IKcUAAAAABWIOnylXhpnFYYJTQq-aL8VcDIr' onVerify={this.onVerify} />
                      </div>
                      <div style={this.state.verified ? {} : { display: 'none' }} className='col-md-6 col-12'>
                        <button onClick={(e) => this.handleEmailFormSubmit(e)}>Send Message</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*Contact section end*/}
        </div>
        {/*====================  End of content page content  ====================*/}
      </div>
    );
  }
}

export default Contact;
