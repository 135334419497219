import React, { Component } from 'react';

class Projectors extends Component {
  render() {
    return (
      <div className='col-12 section-space--top--30'>
        <div className='service-grid-item service-grid-item--style2'>
          {/* <div className='service-grid-item__image'> ## image above box
            <div className='service-grid-item__image-wrapper'>
              <img src={`/assets/img/service/serviceShort.jpg`} className='img-fluid' alt='Service Grid' />
            </div>
          </div> */}
          <div name='Projectors' className='service-grid-item__content'>
            <h3 className='title'>
              <img width={50} alt={'icon'} style={{ paddingRight: '10px' }} src='/assets/img/icons/projector.png' />
              Projectors
            </h3>
            <p className='subtitle'>
              Need a projector for your presentation, movie, or other event? We've got you covered. Our powerful NEC laser projectors will give your audience
              a crystal clear viewing experience.
            </p>
            {/* <a
              href={`${process.env.PUBLIC_URL}/contact-us`}
              className='ht-btn--default ht-btn--default--dark-hovers'
            >
              CONTACT US
            </a> */}
          </div>
        </div>
      </div>
    );
  }
}

export default Projectors;
