import React, { Component } from 'react';

class AntivirusSecurityBackup extends Component {
  render() {
    return (
      <div className='col-12 section-space--top--30'>
        <div className='service-grid-item service-grid-item--style2'>
          {/* <div className='service-grid-item__image'> ## image above box
            <div className='service-grid-item__image-wrapper'>
              <img src={`/assets/img/service/serviceShort.jpg`} className='img-fluid' alt='Service Grid' />
            </div>
          </div> */}
          <div name='Antivirus, Security & Backup' className='service-grid-item__content'>
            <h3 className='title'>
              <img width={50} alt={'icon'} style={{ paddingRight: '10px' }} src='/assets/img/icons/avast.png' />
              Antivirus, Security & Backup
            </h3>
            <p className='subtitle'>
              Protect your computers, servers and network with professional antivirus, antimalware and antispyware
              solutions from Avast. We offer standalone and cloud managed software as well as content filtering, email
              security, and cloud and local backup solutions.
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default AntivirusSecurityBackup;
