import React, { Component } from 'react';

class Networking extends Component {
  render() {
    return (
      <div className='col-12 section-space--top--30'>
        <div className='service-grid-item service-grid-item--style2'>
          {/* <div className='service-grid-item__image'> ## image above box
            <div className='service-grid-item__image-wrapper'>
              <img src={`/assets/img/service/serviceShort.jpg`} className='img-fluid' alt='Service Grid' />
            </div>
          </div> */}
          <div name='Networking' className='service-grid-item__content'>
            <h3 className='title'>
              <img width={50} alt={'icon'} style={{ paddingRight: '10px' }} src='/assets/img/icons/lan.png' />
              Networking
            </h3>
            <p className='subtitle'>
              Having a solid network is essential for all other devices to work as they should. We provide scalable
              wireless network systems, point to point wireless solutions, managed switches, routers and security
              devices to keep you connected!
            </p>
            <p className='subtitle'>
              We install SonicWALL professional network security solutions to provide remote access (VPN) capabilities,
              email and Microsoft 365 security, web content filtering, network level antivirus as well as malware and
              ransomware protection.
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default Networking;
