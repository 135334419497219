import React, { Component } from 'react';
import { Link } from 'react-scroll';

class Sidebar extends Component {
  render() {
    let data = [
      {
        sidebarListIcon: 'flaticon-015-cart',
        sidebarListText: 'Construction'
      },
      {
        sidebarListIcon: 'flaticon-010-tank-1',
        sidebarListText: 'House Remodel'
      },
      {
        sidebarListIcon: 'flaticon-002-welding',
        sidebarListText: 'Interior Design'
      },
      {
        sidebarListIcon: 'flaticon-004-walkie-talkie',
        sidebarListText: 'Floors & Roofs'
      },
      {
        sidebarListIcon: 'flaticon-042-monitor',
        sidebarListText: 'Plumbing'
      },
      {
        sidebarListIcon: 'flaticon-050-boxes',
        sidebarListText: 'Electricity'
      }
    ];

    let Datalist = data.map((val, i) => {
      return (
        <li key={i}>
          <Link to={val.sidebarListText} spy={true} smooth={true} offset={-10} duration={500}>
            <i className={val.sidebarListIcon} />
            {val.sidebarListText}
          </Link>
        </li>
      );
    });

    return (
      <div>
        <div className='sidebar-wrapper'>
          <div className='sidebar'>
            <h3 className='sidebar-title'>Services</h3>
            <ul className='sidebar-list'>{Datalist}</ul>
          </div>
          <div className='sidebar'>
            <h3 className='sidebar-title'>Download brochure</h3>
            <ul className='sidebar-list'>
              <li>
                <Link to='/'>
                  <i className='fa fa-file-pdf-o' />
                  Brochures.PDF
                </Link>
              </li>
              <li>
                <Link to='/'>
                  <i className='fa fa-file-word-o' />
                  Brochures.DOC
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

export default Sidebar;
