import React from 'react';
import ReactDOM from 'react-dom';
import Root from './App';
import * as serviceWorker from './serviceWorker';
import { withRouter } from 'react-router-dom';
import ReactGA from 'react-ga';


// google analytics tracking id
const TRACKING_ID = "UA-201432509-1"; 
ReactGA.initialize(TRACKING_ID);

// More GA tracking stuff
const RouteChangeTracker = ({ history }) => {

    history.listen((location, action) => {
        ReactGA.set({ page: location.pathname });
        ReactGA.pageview(location.pathname);
    });

    return <div></div>;
};

export default withRouter(RouteChangeTracker);


function App() {
    return <Root />;
}

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
