import React, { Component } from 'react';

class Servers extends Component {
  render() {
    return (
      <div className='col-12 section-space--top--30'>
        <div className='service-grid-item service-grid-item--style2'>
          {/* <div className='service-grid-item__image'> ## image above box
            <div className='service-grid-item__image-wrapper'>
              <img src={`/assets/img/service/serviceShort.jpg`} className='img-fluid' alt='Service Grid' />
            </div>
          </div> */}
          <div name='Servers' className='service-grid-item__content'>
            <h3 className='title'>
              <img width={50} alt={'icon'} style={{ paddingRight: '10px' }} src='/assets/img/icons/server.png' />
              Servers
            </h3>
            <p className='subtitle'>
              Dell PowerEdge servers provide your business the power and services you need while balancing budget,
              redundancy and room for growth. We work with you to provide a custom server solution to host your
              applications, share files and manage your network or domain.
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default Servers;
