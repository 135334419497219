import React, { Component } from 'react';
import HeroSliderOne from './components/HeroSliderOne';
import ServicesOverview from './service/components/ServicesOverview';
// import Funfact from './components/Funfact';
// import VideoCta from './components/VideoCta';
// import ProjectSlider from './components/ProjectSlider';
// import TeamJob from './components/TeamJob';
// import TestimonialSlider from './components/TestimonialSlider';
// import BlogGrid from './components/BlogGrid';
import BrandLogoSlider from './components/BrandLogoSlider';

class HomeOne extends Component {
  render() {
    return (
      <div>
        {/* Hero slider */}
        <HeroSliderOne />
        {/* Service grid slider */} {/*====================  service page content ====================*/}
        <div className='page-wrapper section-space--inner--60'>
          {/*Service section start*/}
          <div className='service-section'>
            <div className='container'>
              <div className='row'>
                <div className='col-lg-12'>
                  <div className='section-title-area text-center'>
                    <h2 className='section-title section-space--bottom--50'>Our Services</h2>
                  </div>
                </div>
                <div className='col-lg-12'>
                  <div className='service-item-wrapper'>
                    <div className='row'>
                      <ServicesOverview />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*Service section end*/}
        </div>
        {/* Fun fact */}
        {/* <Funfact /> */}
        {/* Video CTA */}
        {/* <VideoCta/> */}
        {/* Project Slider */}
        {/* <ProjectSlider/> */}
        {/* Team job */}
        {/* <TeamJob/> */}
        {/* Testimonial Slider */}
        {/* <TestimonialSlider/> */}
        {/* Blog grid */}
        {/* <BlogGrid background = "grey-bg" /> */}
        {/* Brand logo */}
        <BrandLogoSlider background='grey-bg' />
      </div>
    );
  }
}

export default HomeOne;
