import React, { Component } from 'react';

class SharePoint extends Component {
  render() {
    return (
      <div className='col-12 section-space--top--30'>
        <div className='service-grid-item service-grid-item--style2'>
          {/* <div className='service-grid-item__image'> ## image above box
            <div className='service-grid-item__image-wrapper'>
              <img src={`/assets/img/service/serviceShort.jpg`} className='img-fluid' alt='Service Grid' />
            </div>
          </div> */}
          <div name='SharePoint Integration Services' className='service-grid-item__content'>
            <h3 className='title'>
              <img
                width={50}
                alt={'icon'}
                style={{ paddingRight: '10px' }}
                src='/assets/img/icons/SharePointIntegration.png'
              />
              SharePoint Integration Services
            </h3>
            <p className='subtitle'>
              Microsoft SharePoint is a web based application that integrates with Microsoft Office. It can be used as a
              secure place for an organization to store, organize, share, and access documents and information from
              almost any device.
            </p>
            <p className='subtitle'>
              Ensure everyone is working on the same version of a file instead of having multiple copies on USB drives
              and in email. We can install, configure, and support SharePoint as well as upgrade older installations.
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default SharePoint;
