import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Sidebar from './videosurveillance/Sidebar';
import ServiceGallery from './videosurveillance/ServiceGallery';
import BrandLogoSlider from '../components/BrandLogoSlider';

import RecordingSystems from './components/RecordingSystems';
import Milestone from './components/Milestone';
import CamerasHardware from './components/CamerasHardware';
import LongRangeRemoteCameras from './components/LongRangeRemoteCameras';
import COSTARS from './components/COSTARS';

class ServiceDetailsLeftSidebar extends Component {
  render() {
    return (
      <div>
        {/* breadcrumb */}
        {/*====================  breadcrumb area ====================*/}
        <div className='breadcrumb-area video-surveillance-bg'>
          <div className='container'>
            <div className='row'>
              <div className='col'>
                <div className='page-banner text-center'>
                  <h1>Video Surveillance</h1>
                  <ul className='page-breadcrumb'>
                    <li>
                      <Link to='/'>Home</Link>
                    </li>
                    <li>
                      <Link to={`${process.env.PUBLIC_URL}/services`}>Services</Link>
                    </li>
                    <li>Video Surveillance</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*====================  End of breadcrumb area  ====================*/}

        <div className='page-wrapper section-space--inner--120'>
          {/*Service section start*/}
          <div className='service-section'>
            <div className='container'>
              <div className='row'>
                <div className='col-lg-8 col-12 order-1 order-lg-2'>
                  <div className='service-details'>
                    {/* service gallery */}
                    <ServiceGallery />

                    <div className='content section-space--top--30'>
                      <div className='row'>
                        <div className='col-12'>
                          <h2>Video Surveillance</h2>
                          <p>
                            Physical security is an essential part to many of todays buildings. We can help secure your
                            building and property by designing and installing a custom network based video surveillance
                            solution. As a skilled IT provider, we’re able to provide a solid wireless or wired network
                            backbone to support your camera installation. From long range wireless links to in building
                            switches and fiber, we have it covered.
                          </p>
                        </div>
                        <COSTARS />
                        <RecordingSystems />
                        <Milestone />
                        <CamerasHardware />
                        <LongRangeRemoteCameras />
                        
                      </div>
                    </div>
                  </div>
                </div>
                <div className='col-lg-4 col-12 order-2 order-lg-1'>
                  <Sidebar />
                </div>
              </div>
            </div>
          </div>
          {/*Service section end*/}
        </div>

        {/* Brand logo */}
        <BrandLogoSlider background='grey-bg' />
      </div>
    );
  }
}

export default ServiceDetailsLeftSidebar;
