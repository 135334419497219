import React, { Component } from 'react';

class AVMeeting extends Component {
  render() {
    return (
      <div className='col-12 section-space--top--30'>
        <div className='service-grid-item service-grid-item--style2'>
          {/* <div className='service-grid-item__image'> ## image above box
            <div className='service-grid-item__image-wrapper'>
              <img src={`/assets/img/service/serviceShort.jpg`} className='img-fluid' alt='Service Grid' />
            </div>
          </div> */}
          <div name='Meeting Spaces' className='service-grid-item__content'>
            <h3 className='title'>
              <img width={50} alt={'icon'} style={{ paddingRight: '10px' }} src='/assets/img/icons/meetingspace.png' />
              Meeting Spaces
            </h3>
            <p className='subtitle'>
              Meeting spaces house some of the most important discussions and presentations within your business. Having the right
              AV system in your meeting space helps maximize time and gives your audience a great experience. Shure Microflex conferencing microphones 
              provide a quality wireless audio solution for voice amplification, recording, and live streaming.
            </p>
            <a
              href={`${process.env.PUBLIC_URL}/contact-us`}
              className='ht-btn--default ht-btn--default--dark-hovers'
            >
              CONTACT US
            </a>
          </div>
        </div>
      </div>
    );
  }
}

export default AVMeeting;
