import React, { Component } from 'react';
import { Link } from 'react-router-dom';
// import FeatureIcon from '../components/FeatureIcon';
// // import Funfact from '../components/Funfact';
// import TeamMemberGrid from '../components/TeamMemberGrid';
// import TestimonialSlider from '../components/TestimonialSlider';
import BrandLogoSlider from '../components/BrandLogoSlider';

// import ModalVideo from 'react-modal-video';
class Support extends Component {
  constructor() {
    super();
    this.state = {
      isOpen: false,
    };
    this.openModal = this.openModal.bind(this);
  }

  openModal() {
    this.setState({ isOpen: true });
  }

  render() {
    let data = [
      {
        pageLink: '/downloads/TeamViewer QS',
        img: 'TeamViewer-QS.jpg',
        projectTitle: 'TeamViewer QS',
        newTab: false,
      },
      {
        pageLink: 'https://support.avast.com/en-eu/article/Install-Pro-Antivirus',
        img: 'Avast-Pro-Antivirus.jpg',
        projectTitle: 'Avast Pro Antivirus',
        newTab: true,
      },
      {
        pageLink: 'https://www5.ibackup.com/newibackup/signupcontinue?p=sltechnology',
        img: 'iBackup.jpg',
        projectTitle: 'iBackup',
        newTab: true,
      },
    ];

    let Datalist = data.map((val, i) => {
      return (
        <div className='col-lg-4 col-sm-6 col-12 section-space--bottom--30' key={i}>
          <div className='service-grid-item service-grid-item--style2'>
            <div className='service-grid-item__image'>
              <div className='service-grid-item__image-wrapper'>
                <a target={val.newTab ? '_blank' : '_top'} rel='noopener noreferrer' href={`${val.pageLink}`}>
                  <img src={`assets/img/support/${val.img}`} className='img-fluid' alt='' />
                </a>
              </div>
            </div>
            <div className='service-grid-item__content'>
              <h3 className='title'>
                <a href={`${val.pageLink}`}>{val.projectTitle}</a>
              </h3>
            </div>
          </div>
        </div>
      );
    });

    // const buttonStyle = {
    //   marginTop: 0,
    // };

    return (
      <div>
        {/* breadcrumb */}
        {/*====================  breadcrumb area ====================*/}
        <div className='breadcrumb-area support-bg'>
          <div className='container'>
            <div className='row'>
              <div className='col'>
                <div className='page-banner text-center'>
                  <h1>Support</h1>
                  <ul className='page-breadcrumb'>
                    <li>
                      <Link to='/'>Home</Link>
                    </li>
                    <li>Support</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*====================  End of breadcrumb area  ====================*/}

        <div className='page-wrapper section-space--inner--top--50'>
          {/*Suppport section start*/}
          <div className='about-section section-space--inner--bottom--30'>
            <div className='container'>
              <div className='row row-25 align-items-center'>
                {/* <div className="col-lg-6 col-12 mb-30"> !!!!!!!!!!!!!!!!!! video if needed in future (change colume values of below div to: 'col-lg-6 col-12 mb-30')
                                    <div className="about-image-two">
                                        <img src="assets/img/about/about-3.jpg" alt="" />
                                        <span className="video-popup">
                                            <ModalVideo channel="youtube" isOpen={this.state.isOpen} videoId="OrS-93U4AYQ" onClose={() => this.setState({ isOpen: false })} />
                                            <button onClick={this.openModal}>
                                                <i className="ion-ios-play-outline" />
                                            </button>
                                        </span>
                                    </div>
                                </div> */}
                <div className='col-lg-12 col-12 mb-30'>
                  <div className='about-content-two'>
                    <h3>We're here to help!</h3>
                    <h4>Please use the links below as instructed by our support technician. <b>Need support?</b> Call us at <a href="tel:7176290662">717-629-0662</a> or 
                    contact us another way <a href="contact-us">here</a>.</h4>

                    {/* <div className='contact-form'>
                      <button id='feedback-form' style={buttonStyle}>
                        Create Support Ticket
                      </button>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*Suppport section end*/}

          {/*====================  support page content ====================*/}
          <div className='page-wrapper section-space--inner--bottom--50'>
            {/*Projects section start*/}
            <div className='project-section'>
              <div className='container'>
                <div className='row'>
                  <div className='col-lg-12'>
                    <div className='project-item-wrapper'>
                      <div className='row'>{Datalist}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/*Projects section end*/}
          </div>

          {/*====================  End of support page content  ====================*/}

          {/* Brand logo */}
          <BrandLogoSlider background='' />
        </div>
      </div>
    );
  }
  s;
}

export default Support;
