import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import FeatureIcon from '../components/FeatureIcon';
// import Funfact from '../components/Funfact';
import TeamMemberGrid from '../components/TeamMemberGrid';
// import TestimonialSlider from '../components/TestimonialSlider';
import BrandLogoSlider from '../components/BrandLogoSlider';
// import AboutGallery1 from './components/AboutGallery1';

// import ModalVideo from 'react-modal-video';
class About extends Component {
  constructor() {
    super();
    this.state = {
      isOpen: false,
    };
    this.openModal = this.openModal.bind(this);
  }

  openModal() {
    this.setState({ isOpen: true });
  }

  render() {
    return (
      <div>
        {/* breadcrumb */}
        {/*====================  breadcrumb area ====================*/}
        <div className='breadcrumb-area services-bg'>
          <div className='container'>
            <div className='row'>
              <div className='col'>
                <div className='page-banner text-center'>
                  <h1>About Us</h1>
                  <ul className='page-breadcrumb'>
                    <li>
                      <Link to='/'>Home</Link>
                    </li>
                    <li>About Us</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*====================  End of breadcrumb area  ====================*/}

        <div className='page-wrapper section-space--inner--top--50'>
          {/*About section start*/}
          <div className='about-section section-space--inner--bottom--50'>
            <div className='container'>
              <div className='row row-25 align-items-center'>
                {/* <div className="col-lg-6 col-12 mb-30"> !!!!!!!!!!!!!!!!!! video if needed in future (change colume values of below div to: 'col-lg-6 col-12 mb-30')
                                    <div className="about-image-two">
                                        <img src="assets/img/about/about-3.jpg" alt="" />
                                        <span className="video-popup">
                                            <ModalVideo channel="youtube" isOpen={this.state.isOpen} videoId="OrS-93U4AYQ" onClose={() => this.setState({ isOpen: false })} />
                                            <button onClick={this.openModal}>
                                                <i className="ion-ios-play-outline" />
                                            </button>
                                        </span>
                                    </div>
                                </div> */}
                <div className='col-lg-12 col-12 mb-30'>
                  <div className='about-content-two'>
                    <h3>Welcome to SL Technology</h3>
                    <h4>
                      Established in 2008, SL Technology has been serving the unique technology needs of businesses,
                      schools, churches and individuals of Lancaster County and beyond for over a decade.
                    </h4>
                    <p>
                      A Pennsylvania certified small business, SL Technology specializes in custom development and IT
                      solutions for clients in a variety of markets while providing exceptional customer support.
                    </p>
                    <p>
                      SL Technology can assist your company in a variety of ways- click the button below to learn more on how we can
                      help you succeed.
                    </p>
                    <Link to='services' className='ht-btn--default ht-btn--default--dark-hover section-space--top--20'>
                      Our Services
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*About section end*/}

          {/* Feature Icon */}
          <FeatureIcon background='grey-bg' />

          {/*About section start*/}
          <div className='about-section section-space--inner--50'>
            <div className='container'>
              <div className='about-wrapper row'>
                <div className='col-sm-6 col-12 order-1 order-lg-2'>
                  <div className='about-image about-image-1'>
                    <img src='assets/img/about/about-1.jpg' alt='' />
                  </div>
                </div>
                <div className='col-sm-6 col-12 order-2 order-lg-3'>
                  <div className='about-image about-image-2'>
                    {/* <AboutGallery1 /> */}
                    <img src='assets/img/about/about-2.jpg' alt='' />
                  </div>
                </div>
                <div className='col-lg-6 col-12 order-3 order-lg-1'>
                  <div className='about-content about-content-1'>
                    <h1>
                      <span>20+</span>Years of Experience
                    </h1>
                    <p>
                      Our team has a wide range of experience and we’re eager to work with you to accomplish your
                      technology goals! We specialize in supporting small to medium sized businesses, schools and
                      colleges with a variety of technology needs ranging from basic IT support to user identity
                      synchronization and customization.
                    </p>
                  </div>
                </div>
                <div className='col-lg-6 col-12 order-4'>
                  <div className='about-content about-content-2'>
                    <p>
                      We are based in the heart of Lancaster County in New Holland, PA. Our office is open by
                      appointment and staffed during regular business hours. We have a mix of remote and office based
                      employees that make up the SL Technology team. We love conquering unique tech challenges and can't
                      wait to talk to you about your project!
                    </p>
                    <Link
                      to={`${process.env.PUBLIC_URL}/contact-us`}
                      className='ht-btn--default ht-btn--default--dark-hover section-space--top--20'
                    >
                      Contact us
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*About section end*/}

          {/* Fun fact */}
          {/* <Funfact /> */}

          {/* Team member */}
          <TeamMemberGrid />

          {/* Testimonial Slider */}
          {/* <TestimonialSlider /> */}

          {/* Brand logo */}
          <BrandLogoSlider background='' />
        </div>

        {/* Footer */}
        {/* <Footer /> */}
      </div>
    );
  }
}

export default About;
