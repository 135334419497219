import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Sidebar from './worship/Sidebar';
import ServiceGallery from './worship/ServiceGallery';
import BrandLogoSlider from '../components/BrandLogoSlider';

// IT Service Modules
import AntivirusSecurityBackup from '../service/components/AntivirusSecurityBackup';
import ComputerRepair from '../service/components/ComputerRepair';
import ComputerSales from '../service/components/ComputerSales';
import InternetVoiceServiceConsulting from '../service/components/InternetVoiceServiceConsulting';
import MicrosoftOffice365 from '../service/components/MicrosoftOffice365';
import Networking from '../service/components/Networking';
import VoiceOverIP from '../service/components/VoiceOverIP';

// AV Service Modules
import AVIntergration from '../service/components/AVIntergration';
import AVSales from '../service/components/AVSales';
import AVRental from '../service/components/AVRental';

class ServiceDetailsLeftSidebar extends Component {
  render() {
    return (
      <div>
        {/* breadcrumb */}
        {/*====================  breadcrumb area ====================*/}
        <div className='breadcrumb-area worship-bg'>
          <div className='container'>
            <div className='row'>
              <div className='col'>
                <div className='page-banner text-center'>
                  <h1>House of Worship</h1>
                  <ul className='page-breadcrumb'>
                    <li>
                      <Link to='/'>Home</Link>
                    </li>
                    <li>
                      <Link to='/solutions'>Solutions For...</Link>
                    </li>
                    <li>House of Worship</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*====================  End of breadcrumb area  ====================*/}

        <div className='page-wrapper section-space--inner--120'>
          {/*Service section start*/}
          <div className='service-section'>
            <div className='container'>
              <div className='row'>
                <div className='col-lg-8 col-12 order-1 order-lg-2'>
                  <div className='service-details'>
                    {/* service gallery */}
                    <ServiceGallery />

                    {/*Service section start*/}
                    <div className='service-section'>
                      <div className='row'>
                        <div className='col-lg-12'>
                          <div className='service-item-wrapper'>
                            <div className='row'>
                              <div className='col-12 section-space--top--20'>
                                <h2>House of Worship</h2>
                                <p>
                                  Elevate your worship experience with solutions from computers and Wi-Fi in the office
                                  to audio, video and streaming in the sanctuary. Non profit discounts from Microsoft
                                  for email and Office applications and special house of workshop discounts on hardware
                                  help make technogy affordable.
                                </p>
                              </div>

                              <AntivirusSecurityBackup />
                              <ComputerRepair />
                              <ComputerSales />
                              <InternetVoiceServiceConsulting />
                              <MicrosoftOffice365 />
                              <Networking />
                              <VoiceOverIP />

                              <AVIntergration />
                              <AVSales />
                              <AVRental />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/*Service section end*/}
                  </div>
                </div>
                <div className='col-lg-4 col-12 order-2 order-lg-1'>
                  <Sidebar />
                </div>
              </div>
            </div>
          </div>
          {/*Service section end*/}
        </div>
        {/* Brand logo */}
        <BrandLogoSlider background='grey-bg' />
      </div>
    );
  }
}

export default ServiceDetailsLeftSidebar;
