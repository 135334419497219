import React, { Component } from 'react';
import { Link } from 'react-router-dom';
// import Funfact from '../components/Funfact';
import BrandLogoSlider from '../components/BrandLogoSlider';

// import ModalVideo from 'react-modal-video';
class Dropoff extends Component {
  constructor() {
    super();
    this.state = {
      isOpen: false,
    };
    this.openModal = this.openModal.bind(this);
  }

  openModal() {
    this.setState({ isOpen: true });
  }

  render() {
    const buttonStyle = {
      marginTop: 0,
    };
    return (
      <div>
        {/* breadcrumb */}
        {/*====================  breadcrumb area ====================*/}
        <div className='breadcrumb-area breadcrumb-bg'>
          <div className='container'>
            <div className='row'>
              <div className='col'>
                <div className='page-banner text-center'>
                  <h1>Computer Dropoff</h1>
                  <ul className='page-breadcrumb'>
                    <li>
                      <Link to='/'>Home</Link>
                    </li>
                    <li>Dropoff</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*====================  End of breadcrumb area  ====================*/}

        <div className='page-wrapper section-space--inner--top--120'>
          {/*About section start*/}
          <div className='about-section section-space--inner--bottom--120'>
            <div className='container'>
              <div className='row row-25 align-items-center'>
                {/* <div className="col-lg-6 col-12 mb-30"> !!!!!!!!!!!!!!!!!! video if needed in future (change colume values of below div to: 'col-lg-6 col-12 mb-30')
                                    <div className="about-image-two">
                                        <img src="assets/img/about/about-3.jpg" alt="" />
                                        <span className="video-popup">
                                            <ModalVideo channel="youtube" isOpen={this.state.isOpen} videoId="OrS-93U4AYQ" onClose={() => this.setState({ isOpen: false })} />
                                            <button onClick={this.openModal}>
                                                <i className="ion-ios-play-outline" />
                                            </button>
                                        </span>
                                    </div>
                                </div> */}
                <div className='col-lg-12 col-12 mb-30'>
                  <div className='about-content-two'>
                    <h3>Welcome to SL Technology</h3>
                    <div style={this.state.showMyComponent ? { display: 'none' } : {}} className='contact-form'>
                      <div className='row row-10'>
                        <div className='col-7'>
                          <input
                            value={this.state.password}
                            onChange={(e) => this.setState({ password: e.target.value })}
                            onKeyDown={this._handleKeyDown}
                            type='password'
                            placeholder='password'
                          />
                        </div>
                        <div className='col-5'>
                          <button style={buttonStyle} onClick={(e) => this.handlePasswordSubmit(e)}>
                            Login
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Brand logo */}
          <BrandLogoSlider background='' />
        </div>
      </div>
    );
  }
}

export default Dropoff;
