import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Sidebar from './itsupport/Sidebar';
import ServiceGallery from './itsupport/ServiceGallery';
import BrandLogoSlider from '../components/BrandLogoSlider';

// Service Modules
import AntivirusSecurityBackup from './components/AntivirusSecurityBackup';
import ComputerRepair from './components/ComputerRepair';
import ComputerSales from './components/ComputerSales';
import InternetVoiceServiceConsulting from './components/InternetVoiceServiceConsulting';
import MicrosoftOffice365 from './components/MicrosoftOffice365';
import Networking from './components/Networking';
import Servers from './components/Servers';
import SharePoint from './components/SharePoint';
import VoiceOverIP from './components/VoiceOverIP';

class ServiceDetailsLeftSidebar extends Component {
  render() {
    return (
      <div>
        {/* breadcrumb */}
        {/*====================  breadcrumb area ====================*/}
        <div className='breadcrumb-area it-support-bg'>
          <div className='container'>
            <div className='row'>
              <div className='col'>
                <div className='page-banner text-center'>
                  <h1>IT Support</h1>
                  <ul className='page-breadcrumb'>
                    <li>
                      <Link to='/'>Home</Link>
                    </li>
                    <li>
                      <Link to={`${process.env.PUBLIC_URL}/services`}>Services</Link>
                    </li>
                    <li>IT Support</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*====================  End of breadcrumb area  ====================*/}

        <div className='page-wrapper section-space--inner--120'>
          {/*Service section start*/}
          <div className='service-section'>
            <div className='container'>
              <div className='row'>
                <div className='col-lg-8 col-12 order-1 order-lg-2'>
                  <div className='service-details'>
                    {/* service gallery */}
                    <ServiceGallery />

                    {/*Service section start*/}
                    <div className='service-section'>
                      <div className='row'>
                        <div className='col-lg-12'>
                          <div className='service-item-wrapper'>
                            <div className='row'>
                              <div className='col-12 section-space--top--20'>
                                <h2>IT Support</h2>
                                <p>
                                  SL offers flexible options to support your IT needs. Our number one priority is
                                  fitting the right solution for your needs and keeping your systems up and running. We
                                  can work with you on a project basis, block of time or support contract.
                                </p>
                                <p>
                                  We provide a wide range of solutions and support and specialize in businesses with
                                  1-50 computers, supporting your computers, servers, phones, backup, antivirus,
                                  Microsoft Office, networking and internet connection.
                                </p>
                                <p>
                                  <b>Looking for a new IT provider?</b> Contact us to conduct a free assessment of your
                                  current systems. We’ll take a look at everything- from making sure your computers are
                                  current, secure and properly functioning to ensuring you’re getting the best price for
                                  internet and voice services.
                                </p>
                              </div>
                              <AntivirusSecurityBackup />
                              <ComputerRepair />
                              <ComputerSales />
                              <InternetVoiceServiceConsulting />
                              <MicrosoftOffice365 />
                              <Networking />
                              <Servers />
                              <SharePoint />
                              <VoiceOverIP />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/*Service section end*/}
                  </div>
                </div>
                <div className='col-lg-4 col-12 order-2 order-lg-1'>
                  <Sidebar />
                </div>
              </div>
            </div>
          </div>
          {/*Service section end*/}
        </div>
        {/* Brand logo */}
        <BrandLogoSlider background='grey-bg' />
      </div>
    );
  }
}

export default ServiceDetailsLeftSidebar;
