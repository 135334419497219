import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Sidebar from './softwaredevelopment/Sidebar';
import ServiceGallery from './softwaredevelopment/ServiceGallery';
import BrandLogoSlider from '../components/BrandLogoSlider';

import IdentityManagementSolutions from './components/IdentityManagementSolutions';
import SharePointIntegrationServices from './components/SharePointIntegrationServices';

class ServiceDetailsLeftSidebar extends Component {
  render() {
    return (
      <div>
        {/* breadcrumb */}
        {/*====================  breadcrumb area ====================*/}
        <div className='breadcrumb-area software-development-bg'>
          <div className='container'>
            <div className='row'>
              <div className='col'>
                <div className='page-banner text-center'>
                  <h1>Software Development</h1>
                  <ul className='page-breadcrumb'>
                    <li>
                      <Link to='/'>Home</Link>
                    </li>
                    <li>
                      <Link to={`${process.env.PUBLIC_URL}/services`}>Services</Link>
                    </li>
                    <li>Software Development</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*====================  End of breadcrumb area  ====================*/}

        <div className='page-wrapper section-space--inner--120'>
          {/*Service section start*/}
          <div className='service-section'>
            <div className='container'>
              <div className='row'>
                <div className='col-lg-8 col-12 order-1 order-lg-2'>
                  <div className='service-details'>
                    {/* service gallery */}
                    <ServiceGallery />

                    <div className='content section-space--top--30'>
                      <div className='row'>
                        <div className='col-12'>
                          <h2>Software Development</h2>
                          <p>
                            If there isn’t an out-of-the-box solution to meet your organization’s needs, we can develop
                            a custom application that will meet those requirements. Using custom developed software, we
                            can improve your operations and productivity. We work directly with you and your staff to
                            find the custom solution that will work best for everyone.
                          </p>
                        </div>
                        <IdentityManagementSolutions />
                        <SharePointIntegrationServices />
                      </div>
                    </div>
                  </div>
                </div>
                <div className='col-lg-4 col-12 order-2 order-lg-1'>
                  <Sidebar />
                </div>
              </div>
            </div>
          </div>
          {/*Service section end*/}
        </div>

        {/* Brand logo */}
        <BrandLogoSlider background='grey-bg' />
      </div>
    );
  }
}

export default ServiceDetailsLeftSidebar;
