import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import BrandLogoSlider from '../components/BrandLogoSlider';

class Services extends Component {
  render() {
    let data = [
      {
        pageLink: 'solutions/small-business',
        img: 'small-business.jpg',
        iconClass: 'flaticon-small-business',
        serviceTitle: 'Small Businesses',
        serviceSubtitle:
          'As a small business ourselves, we understand the challenges small businesses face and are here to help technology work for you at a price you can afford. We support small business throughout Lancaster, Berks and Chester counties.',
      },
      {
        pageLink: 'solutions/education',
        img: 'education.jpg',
        iconClass: 'flaticon-education',
        serviceTitle: 'Education',
        serviceSubtitle:
          'With over a decade of education technology experience we provide user management solutions using Microsoft Identity Manger and Forefront Identity Manager to integrate with your software systems, custom application development services, SharePoint integration as well as hardware solutions including audiovisual and physical security on PA COSTARS contracts.',
      },
      {
        pageLink: 'solutions/worship',
        img: 'worship.jpg',
        iconClass: 'flaticon-house-of-worship',
        serviceTitle: 'House of Worship',
        serviceSubtitle:
          'Elevate your worship experience with solutions from computers and Wi-Fi in the office to audio, video and streaming in the sanctuary. Non profit discounts from Microsoft for email and Office applications and special house of workshop discounts on hardware help make technogy affordable.',
      },
    ];

    let Datalist = data.map((val, i) => {
      return (
        <div className='col-lg-4 col-md-6 col-12 section-space--bottom--30' key={i}>
          {' '}
          <Link to={`${process.env.PUBLIC_URL}/${val.pageLink}`}>
            <div className='service-grid-item'>
              <div className='service-grid-item__image'>
                <div className='service-grid-item__image-wrapper'>
                  <img src={`assets/img/solutions/${val.img}`} className='img-fluid' alt='Service Grid' />
                </div>
                <div className='icon'>
                  <i className={val.iconClass} />
                </div>
              </div>
              <div className='service-grid-item__content'>
                <h3 className='title'>
                  <Link to={`${process.env.PUBLIC_URL}/${val.pageLink}`}>{val.serviceTitle}</Link>
                </h3>
                <p className='subtitle'>{val.serviceSubtitle}</p>
                <Link to={`${process.env.PUBLIC_URL}/${val.pageLink}`} className='see-more-link'>
                  SEE MORE
                </Link>
              </div>
            </div>{' '}
          </Link>
        </div>
      );
    });

    return (
      <div>
        {/* breadcrumb */}
        {/*====================  breadcrumb area ====================*/}
        <div className='breadcrumb-area services-bg'>
          <div className='container'>
            <div className='row'>
              <div className='col'>
                <div className='page-banner text-center'>
                  <h1>Solutions For...</h1>
                  <ul className='page-breadcrumb'>
                    <li>
                      <Link to='/'>Home</Link>
                    </li>
                    <li>Solutions For...</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*====================  End of breadcrumb area  ====================*/}

        {/*====================  service page content ====================*/}
        <div className='page-wrapper section-space--inner--120'>
          {/*Service section start*/}
          <div className='service-section'>
            <div className='container'>
              <div className='row'>
                <div className='col-lg-12'>
                  <div className='service-item-wrapper'>
                    <div className='row'>{Datalist}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*Service section end*/}
        </div>

        {/*====================  End of service page content  ====================*/}

        {/* Brand logo */}
        <BrandLogoSlider background='grey-bg' />
      </div>
    );
  }
}

export default Services;
