import React, { Component } from 'react';

class AVWorship extends Component {
  render() {
    return (
      <div className='col-12 section-space--top--30'>
        <div className='service-grid-item service-grid-item--style2'>
          {/* <div className='service-grid-item__image'> ## image above box
            <div className='service-grid-item__image-wrapper'>
              <img src={`/assets/img/service/serviceShort.jpg`} className='img-fluid' alt='Service Grid' />
            </div>
          </div> */}
          <div name='House of Worship' className='service-grid-item__content'>
            <h3 className='title'>
              <img width={50} alt={'icon'} style={{ paddingRight: '10px' }} src='/assets/img/icons/houseofworship.png' />
              House of Worship
            </h3>
            <p className='subtitle'>
              SL is an experienced provider of AV systems for Houses of Worship. From audio and lighting control systems to live streaming solutions, 
              we can help elevate your worship experience.
            </p>
            <a
              href="mailto:avsales@sltechnology.net"
              target='BLANK'
              className='ht-btn--default ht-btn--default--dark-hovers'
            >
              CONTACT US
            </a>
          </div>
        </div>
      </div>
    );
  }
}

export default AVWorship;
