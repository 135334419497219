import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Sidebar from './audiovisual/audiovisualsales/Sidebar';
import ServiceGallery from './audiovisual/audiovisualsales/ServiceGallery';
import BrandLogoSlider from '../components/BrandLogoSlider';

// Service Modules
import AVPricing from './components/AVPricing';
import AVManufacturers from './components/AVManufacturers';
import AVShipping from './components/AVShipping';

class ServiceDetailsLeftSidebar extends Component {
  render() {
    return (
      <div>
        {/* breadcrumb */}
        {/*====================  breadcrumb area ====================*/}
        <div className='breadcrumb-area avsales-bg'>
          <div className='container'>
            <div className='row'>
              <div className='col'>
                <div className='page-banner text-center'>
                  <h1>AV Equipment Sales</h1>
                  <ul className='page-breadcrumb'>
                    <li>
                      <Link to='/'>Home</Link>
                    </li>
                    <li>
                      <Link to={`${process.env.PUBLIC_URL}/services`}>Services</Link>
                    </li>
                    <li>
                      <Link to={`${process.env.PUBLIC_URL}/services/audiovisual`}>Audiovisual</Link>
                    </li>
                    <li>Sales</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*====================  End of breadcrumb area  ====================*/}

        <div className='page-wrapper section-space--inner--120'>
          {/*Service section start*/}
          <div className='service-section'>
            <div className='container'>
              <div className='row'>
                <div className='col-lg-8 col-12 order-1 order-lg-2'>
                  <div className='service-details'>
                    {/* service gallery */}
                    <ServiceGallery />

                    <div className='content section-space--top--30'>
                      <div className='row'>
                        <div className='col-12'>
                          <h2>AV Equipment Sales</h2>
                          <p>
                            Having high quality, reliable equipment is essential for your presentation! With access to dozens of top
                            AV manufactures including Atlas, BrightSign, Chief, Crown, C2G, Da-Lite, dbx, Epson, JBL, Kramer, LG, NEC,
                            Panasonic, Listen, Sharp, SoundCraft, Shure, Samsung, and others, we have the solution you need! As a PA
                            COSTARS member, we have been helping supply PA schools and universities with the tech they need at an
                            affordable price. Contact us today for special pricing on projectors, wireless microphones, sound systems,
                            control equipment, audio consoles, projection screens, displays and more.
                          </p>
                        </div>
                        <AVManufacturers />
                        <AVPricing />
                        <AVShipping />
                      </div>
                    </div>
                  </div>
                </div>
                <div className='col-lg-4 col-12 order-2 order-lg-1'>
                  <Sidebar />
                </div>
              </div>
            </div>
          </div>
          {/*Service section end*/}
        </div>

        {/* Brand logo */}
        <BrandLogoSlider background='grey-bg' />
      </div>
    );
  }
}

export default ServiceDetailsLeftSidebar;
