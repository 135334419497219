import React, { Component } from 'react';
import { Router, Route, Switch } from 'react-router-dom';

import './index.scss';

// Google Analytics
import ReactGA from 'react-ga';
import { createBrowserHistory } from 'history';

import NavBar from './components/ScrollToTop';
import ScrollToTop from './components/NavBar';
import Home from './Home';

import Services from './service/Services';

import ITSupport from './service/ITSupport';
import Audiovisual from './service/audiovisual';
import audiovisualRental from './service/audiovisualRental';
import audiovisualSales from './service/audiovisualSales';
import audiovisualIntegration from './service/audiovisualIntegration'
import VideoSurveillance from './service/VideoSurveillance';
import SoftwareDevelopment from './service/SoftwareDevelopment';
import ServiceDetailsLeftSidebar from './service/ServiceDetailsLeftSidebar';
// import ServiceDetailsRightSidebar from './service/ServiceDetailsRightSidebar';

import Solutions from './solution/Solutions';
import Education from './solution/Education';
import SmallBusiness from './solution/SmallBusiness';
import Worship from './solution/Worship';

import About from './pages/About';
import Contact from './pages/Contact';
import Support from './pages/Support';
import Tech from './pages/Tech';
import Dropoff from './pages/Dropoff';
import Downloads from './pages/Downloads';
import PrivacyPolicy from './pages/PrivacyPolicy.js';

import PageNotFound from './pages/404';
import NoMAtch from './pages/404';

import Footer from './components/Footer';

const history = createBrowserHistory();

// Initialize google analytics page view tracking
const trackingId = 'UA-201432509-1'; // Replace with your Google Analytics tracking ID
ReactGA.initialize(trackingId);

history.listen((location) => {
  ReactGA.set({ page: location.pathname }); // Update the user's current page
  ReactGA.pageview(location.pathname); // Record a pageview for the given page
});

class SLTechnology extends Component {
  render() {
    return (
      <Router history={history}>
        <ScrollToTop />
        <React.Fragment>
          <NavBar />
          <Switch>
            <Route exact path={`${process.env.PUBLIC_URL}/`} component={Home} />
            <Route exact path={`${process.env.PUBLIC_URL}/support`} component={Support} />
            <Route exact path={`${process.env.PUBLIC_URL}/tech`} component={Tech} />
            <Route path={`${process.env.PUBLIC_URL}/downloads/:file`} component={Downloads} />
            <Route exact path={`${process.env.PUBLIC_URL}/dropoff`} component={Dropoff} />
            <Route exact path={`${process.env.PUBLIC_URL}/about-us`} component={About} />
            <Route exact path={`${process.env.PUBLIC_URL}/services`} component={Services} />
            <Route exact path={`${process.env.PUBLIC_URL}/privacy-policy`} component={PrivacyPolicy} />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/service-details-left-sidebar`}
              component={ServiceDetailsLeftSidebar}
            />
            <Route exact path={`${process.env.PUBLIC_URL}/services/it-support`} component={ITSupport} />
            <Route exact path={`${process.env.PUBLIC_URL}/services/audiovisual`} component={Audiovisual} />
            <Route exact path={`${process.env.PUBLIC_URL}/services/audiovisual/rental`} component={audiovisualRental} />
            <Route exact path={`${process.env.PUBLIC_URL}/services/audiovisual/sales`} component={audiovisualSales} />
            <Route exact path={`${process.env.PUBLIC_URL}/services/audiovisual/integration`} component={audiovisualIntegration} />
            <Route exact path={`${process.env.PUBLIC_URL}/services/video-surveillance`} component={VideoSurveillance} />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/services/software-development`}
              component={SoftwareDevelopment}
            />
            <Route exact path={`${process.env.PUBLIC_URL}/solutions`} component={Solutions} />
            <Route exact path={`${process.env.PUBLIC_URL}/solutions/small-business`} component={SmallBusiness} />
            <Route exact path={`${process.env.PUBLIC_URL}/solutions/education`} component={Education} />
            <Route exact path={`${process.env.PUBLIC_URL}/solutions/worship`} component={Worship} />
            <Route exact path={`${process.env.PUBLIC_URL}/contact-us`} component={Contact} />
            <Route exact path={`${process.env.PUBLIC_URL}/404`} component={PageNotFound} />
            <Route component={NoMAtch} />
          </Switch>
          <Footer />
        </React.Fragment>
      </Router>
    );
  }
}

export default SLTechnology;
