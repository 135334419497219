import React, { Component } from 'react';
import Swiper from 'react-id-swiper';
import ContactFooter from './ContactFooter';

class BrandLogoSlider extends Component {
  render() {
    const params = {
      slidesPerView: 7,
      loop: true,
      speed: 800,
      spaceBetween: 30,
      autoplay: {
        delay: 1600,
        disableOnInteraction: true,
      },

      // Responsive breakpoints
      breakpoints: {
        1499: {
          slidesPerView: 7,
        },

        991: {
          slidesPerView: 6,
        },

        767: {
          slidesPerView: 4,
        },

        575: {
          slidesPerView: 3,
        },
      },
    };

    let data = [
      { img: '1.png', logoLink: 'https://www.dgs.pa.gov/COSTARS/Pages/default.aspx' },
      { img: '2.png', logoLink: 'https://www.office.com/' },
      { img: '3.png', logoLink: 'https://www.dell.com/' },
      { img: '4.png', logoLink: 'https://www.milestonesys.com/' },
      { img: '5.png', logoLink: 'https://www.digium.com/' },
      { img: '6.png', logoLink: 'https://www.avast.com/en-us/business' },
      { img: '7.png', logoLink: 'https://www.atlasied.com/' },
      { img: '8.png', logoLink: 'https://www.brightsign.biz/' },
      { img: '9.png', logoLink: 'https://www.necdisplay.com/' },
      { img: '10.png', logoLink: 'https://business.comcast.com/' },
      { img: '11.png', logoLink: 'https://www.sangoma.com/' },
      { img: '12.png', logoLink: 'https://www.axis.com/' },
      { img: '13.png', logoLink: 'https://www.sonicwall.com/' },
      { img: '14.png', logoLink: 'https://www.ui.com/' },
      { img: '15.png', logoLink: 'https://arcules.com/' },
      { img: '16.png', logoLink: 'https://shure.com/' },
      { img: '17.png', logoLink: 'https://cirrusbackup.com/' },
    ];

    let DataList = data.map((val, i) => {
      return (
        <div className='swiper-slide brand-logo-slider__single' key={i}>
          <div className='image text-center'>
            <a href={val.logoLink} target='_blank' rel='noopener noreferrer'>
              <img src={`/assets/img/brand-logo/${val.img}`} className='img-fluid' alt='' />
            </a>
          </div>
        </div>
      );
    });

    return (
      <div>
        <ContactFooter />
        {/*====================  brand logo area ====================*/}
        <div className={`brand-logo-slider-area section-space--inner--50 ${this.props.background}`}>
          <div className='container'>
            <div className='row'>
              <div className='col-lg-12'>
                {/* brand logo slider */}
                <div className='brand-logo-slider__container-area'>
                  <Swiper {...params}>{DataList}</Swiper>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*====================  End of brand logo area  ====================*/}
      </div>
    );
  }
}

export default BrandLogoSlider;
