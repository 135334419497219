import React, { Component } from 'react';

class IdentityManagementSolutions extends Component {
  render() {
    return (
      <div className='col-12 section-space--top--30'>
        <div className='service-grid-item service-grid-item--style2'>
          {/* <div className='service-grid-item__image'> ## image above box
            <div className='service-grid-item__image-wrapper'>
              <img src={`/assets/img/service/serviceShort.jpg`} className='img-fluid' alt='Service Grid' />
            </div>
          </div> */}
          <div name='Identity Management Solutions' className='service-grid-item__content'>
            <h3 className='title'>
              <img width={50} alt={'icon'} style={{ paddingRight: '10px' }} src='/assets/img/icons/mim.png' />
              Identity Management Solutions
            </h3>
            <p className='subtitle'>
              Microsoft Identity Manager (MIM) also previously known as Forefront Identity Manager is a Microsoft
              solution used to synchronize user identities and memberships between a variety of databases, applications
              and directories.
            </p>
            <p className='subtitle'>
              We develop custom Management Agents for MIM to integrate any system that your organization may have. From
              on-premise applications to cloud services, we can provide specific integration into your systems.
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default IdentityManagementSolutions;
