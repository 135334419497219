import React, { Component } from 'react';

class LongRangeRemoteCameras extends Component {
  render() {
    return (
      <div className='col-12 section-space--top--30'>
        <div className='service-grid-item service-grid-item--style2'>
          {/* <div className='service-grid-item__image'> ## image above box
            <div className='service-grid-item__image-wrapper'>
              <img src={`/assets/img/service/serviceShort.jpg`} className='img-fluid' alt='Service Grid' />
            </div>
          </div> */}
          <div name='Long Range/Remote Cameras' className='service-grid-item__content'>
            <h3 className='title'>
              <img width={50} alt={'icon'} style={{ paddingRight: '10px' }} src='/assets/img/icons/wireless-cctv.png' />
              Long Range/Remote Cameras
            </h3>
            <p className='subtitle'>
              We offer a range of reliable wireless solutions for remote cameras and buildings. Ubiquiti point to point
              links are affordable and can also provide internet and network connectivity at remote sites without a hard
              wired connection.
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default LongRangeRemoteCameras;
