import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class Contactfooter extends Component {
  render() {
    return (
      <div>
        {/*====================  fun fact area ====================*/}
        <div className='section-space--inner--20'>
          <div className='container'>
            <div className='row'>
              <div className='col-12 text-center'>
                <h3>We'd love to discuss our services and offerings with you!</h3>
                <Link
                  style={{ fontSize: '20px' }}
                  to={`${process.env.PUBLIC_URL}/contact-us`}
                  className='ht-btn--default ht-btn--default--dark-hovers'
                >
                  <b>Contact us</b>
                </Link>
              </div>
            </div>
          </div>
        </div>
        {/*====================  End of fun fact area  ====================*/}
      </div>
    );
  }
}

export default Contactfooter;
