import React, { Component } from 'react';

class TeamMemberGrid extends Component {
    render() {
        /* team member data */

        let data = [
            {
                profileImage: 'lee.jpg',
                profileTitle: 'Brian Lee',
                profileDesignation: 'Manager, Software Development Services',
                profileEmail: 'blee@sltechnology.net',
                phoneText: '717-629-0662 x103',
                socialLinks: {
                    phone: 'tel:7176290662,103',
                    facebook: '//www.facebook.com',
                    twitter: '//www.twitter.com',
                    linkedin: '//www.linkedin.com',
                    googlePlus: '//plus.google.com',
                },
            },
            {
                profileImage: 'styer.jpg',
                profileTitle: 'John Styer',
                profileDesignation: 'Manager, IT & AV Services',
                profileEmail: 'jstyer@sltechnology.net',
                phoneText: '717-629-0662 x102',
                socialLinks: {
                    phone: 'tel:7176290662,102',
                    facebook: '//www.facebook.com',
                    twitter: '//www.twitter.com',
                    linkedin: '//www.linkedin.com',
                    googlePlus: '//plus.google.com',
                },
            },
            {
                profileImage: 'noah3.jpg',
                profileTitle: 'Noah Keckler',
                profileDesignation: 'Technology Assistant & Office Coordinator',
                profileEmail: 'nkeckler@sltechnology.net',
                phoneText: '717-629-0662 x109',
                socialLinks: {
                    phone: 'tel:7176290662,109',
                    facebook: '//www.facebook.com',
                    twitter: '//www.twitter.com',
                    linkedin: '//www.linkedin.com',
                    googlePlus: '//plus.google.com',
                },
            },
            {
                profileImage: 'sangrey.jpg',
                profileTitle: 'Devon Sangrey',
                profileDesignation: 'IT Installation Technician',
                profileEmail: 'dsangrey@sltechnology.net',
                phoneText: '717-629-0662 x106',
                socialLinks: {
                    phone: 'tel:7176290662,106',
                    facebook: '//www.facebook.com',
                    twitter: '//www.twitter.com',
                    linkedin: '//www.linkedin.com',
                    googlePlus: '//plus.google.com',
                },
            },
            {
                profileImage: 'nate.jpg',
                profileTitle: 'Nate Miller',
                profileDesignation: 'AV Installation Technician',
                profileEmail: 'nmiller@sltechnology.net',
                phoneText: '717-629-0662 x107',
                socialLinks: {
                    phone: 'tel:7176290662,107',
                    facebook: '//www.facebook.com',
                    twitter: '//www.twitter.com',
                    linkedin: '//www.linkedin.com',
                    googlePlus: '//plus.google.com',
                },
            },
            {
                profileImage: 'Stephen.jpg',
                profileTitle: 'Stephen Zelenak',
                profileDesignation: 'IT Support Technician',
                profileEmail: 'szelenak@sltechnology.net',
                phoneText: '717-629-0662 x105',
                socialLinks: {
                    phone: 'tel:7176290662,105',
                    facebook: '//www.facebook.com',
                    twitter: '//www.twitter.com',
                    linkedin: '//www.linkedin.com',
                    googlePlus: '//plus.google.com',
                },
            },
            
            {
                profileImage: 'Matt.jpg',
                profileTitle: 'Matt Bloomer',
                profileDesignation: 'Installation Technician',
                profileEmail: 'mbloomer' + '\n' + '@sltechnology.net',
                phoneText: '717-629-0662 x108',
                socialLinks: {
                    phone: 'tel:7176290662,108',
                    facebook: '//www.facebook.com',
                    twitter: '//www.twitter.com',
                    linkedin: '//www.linkedin.com',
                    googlePlus: '//plus.google.com',
                },
            },
            {
                profileImage: 'Brighton.jpg',
                profileTitle: 'Brighton Van Scyoc',
                profileDesignation: 'IT Support Technician',
                profileEmail: 'bvanscyoc' + '\n' + '@sltechnology.net',
                phoneText: '717-629-0662 x104',
                socialLinks: {
                    phone: 'tel:7176290662,104',
                    facebook: '//www.facebook.com',
                    twitter: '//www.twitter.com',
                    linkedin: '//www.linkedin.com',
                    googlePlus: '//plus.google.com',
                },
            },
        ];

        let Datalist = data.map((val, i) => {
            if (val.socialLinks.phone != null) {
                return (
                    <div className='col-lg-3 col-sm-6 col-12 section-space--bottom--20' key={i}>
                        <div className='team'>
                            <div className='image'>
                                <img src={`assets/img/about/${val.profileImage}`} alt='' />
                            </div>
                            <div className='content'>
                                <h3 className='title'>{val.profileTitle}</h3>
                                <span>{val.profileDesignation}</span>
                                <a href={'mailto:' + val.profileEmail} className='email'>
                                    {val.profileEmail}
                                </a>
                                <a href={val.socialLinks.phone} className='facebook'>
                                    <i className='fa fa-phone' />
                                    {' ' + val.phoneText}
                                </a>
                            </div>
                        </div>
                    </div>
                );
            } else {
                return (
                    <div className='col-lg-3 col-sm-6 col-12 section-space--bottom--20' key={i}>
                        <div className='team'>
                            <div className='image'>
                                <img src={`assets/img/about/${val.profileImage}`} alt='' />
                            </div>
                            <div className='content'>
                                <h3 className='title'>{val.profileTitle}</h3>
                                <span>{val.profileDesignation}</span>
                                <a href={'mailto:' + val.profileEmail} className='email'>
                                    {val.profileEmail}
                                </a>

                                {/* <div className='social'>
                  <a href={val.socialLinks.facebook} className='facebook'>
                    <i className='fa fa-facebook' />
                  </a>
                  <a href={val.socialLinks.twitter} className='twitter'>
                    <i className='fa fa-twitter' />
                  </a>
                  <a href={val.socialLinks.linkedin} className='linkedin'>
                    <i className='fa fa-linkedin' />
                  </a>
                  <a href={val.socialLinks.googlePlus} className='google'>
                    <i className='fa fa-google-plus' />
                  </a>
                </div> */}
                            </div>
                        </div>
                    </div>
                );
            }
        });
        return (
            <div>
                {/*====================  team member area ====================*/}
                <div className='team-member-area section-space--inner--50'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-lg-12'>
                                <div className='section-title-area text-center'>
                                    <h2 className='section-title section-space--bottom--50'>Our Team</h2>
                                </div>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-lg-12'>
                                <div className='team-member-wrapper'>
                                    <div className='row'>
                                        {/* team member list */}
                                        {Datalist}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/*====================  End of team member area  ====================*/}
            </div>
        );
    }
}

export default TeamMemberGrid;
