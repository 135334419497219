import React, { Component } from 'react';

class CamerasHardware extends Component {
  render() {
    return (
      <div className='col-12 section-space--top--30'>
        <div className='service-grid-item service-grid-item--style2'>
          {/* <div className='service-grid-item__image'> ## image above box
            <div className='service-grid-item__image-wrapper'>
              <img src={`/assets/img/service/serviceShort.jpg`} className='img-fluid' alt='Service Grid' />
            </div>
          </div> */}
          <div name='Cameras & Hardware' className='service-grid-item__content'>
            <h3 className='title'>
              <img
                width={50}
                alt={'icon'}
                style={{ paddingRight: '10px' }}
                src='/assets/img/icons/security-camera.png'
              />
              Cameras & Hardware
            </h3>
            <p className='subtitle'>
              We install a variety of cameras and hardware from multiple trusted manufacturers including Axis, Hanwha, and Ubiquiti. From single-sensor budget cameras to 
              multi-sensor premium cameras, we can design a video surveillance system that fits your needs.
            </p>
            <a
              href='https://www.axis.com/en-us/products-and-solutions/'
              target='BLANK'
              className='ht-btn--default ht-btn--default--dark-hovers'
            >
              Axis IP Video Solutions
            </a>
          </div>
        </div>
      </div>
    );
  }
}

export default CamerasHardware;
