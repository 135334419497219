import React, { Component } from 'react';

class MicrosoftOffice365 extends Component {
  render() {
    return (
      <div className='col-12 section-space--top--30'>
        <div className='service-grid-item service-grid-item--style2'>
          {/* <div className='service-grid-item__image'> ## image above box
            <div className='service-grid-item__image-wrapper'>
              <img src={`/assets/img/service/serviceShort.jpg`} className='img-fluid' alt='Service Grid' />
            </div>
          </div> */}
          <div name='Microsoft 365' className='service-grid-item__content'>
            <h3 className='title'>
              <img
                width={50}
                alt={'icon'}
                style={{ paddingRight: '10px' }}
                src='/assets/img/icons/office-365_256x256.png'
              />
              Microsoft 365
            </h3>
            <p className='subtitle'>
              Microsoft 365 (formally Office 365) includes the essential Microsoft Office applications including Word,
              Excel and Outlook as well as business class email and SharePoint and OneDrive cloud file sharing services.
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default MicrosoftOffice365;
