import React, { Component } from 'react';

class AVEducation extends Component {
  render() {
    return (
      <div className='col-12 section-space--top--30'>
        <div className='service-grid-item service-grid-item--style2'>
          {/* <div className='service-grid-item__image'> ## image above box
            <div className='service-grid-item__image-wrapper'>
              <img src={`/assets/img/service/serviceShort.jpg`} className='img-fluid' alt='Service Grid' />
            </div>
          </div> */}
          <div name='Classrooms and Education' className='service-grid-item__content'>
            <h3 className='title'>
              <img width={50} alt={'icon'} style={{ paddingRight: '10px' }} src='/assets/img/icons/education.png' />
              Classrooms and Education
            </h3>
            <p className='subtitle'>
              Having a quality, functional, and easy-to-use AV system in classrooms and educational facilities is important for the
              learning of our next generation. Laser projectors provide stunning picture quality and require very little maintenance. Pairing laser projectors with 
              an AV control panel gives fascilitators quick access to important controls, increasing productivity in the classroom. Control panels
              integrate volume, input, and power controls into an easy and quick control solution. 
            </p>
            <a
              href={`${process.env.PUBLIC_URL}/contact-us`}
              className='ht-btn--default ht-btn--default--dark-hovers'
            >
              CONTACT US
            </a>
          </div>
        </div>
      </div>
    );
  }
}

export default AVEducation;
