import React, { Component } from 'react';

class AVPricing extends Component {
  render() {
    return (
      <div className='col-12 section-space--top--30'>
        <div className='service-grid-item service-grid-item--style2'>
          {/* <div className='service-grid-item__image'> ## image above box
            <div className='service-grid-item__image-wrapper'>
              <img src={`/assets/img/service/serviceShort.jpg`} className='img-fluid' alt='Service Grid' />
            </div>
          </div> */}
          <div name='Pricing' className='service-grid-item__content'>
            <h3 className='title'>
              <img width={50} alt={'icon'} style={{ paddingRight: '10px' }} src='/assets/img/icons/tag.png' />
              Pricing
            </h3>
            <p className='subtitle'>
              SL is here to help you. Thats why we offer competive pricing on our wide variety of AV equipment from dozens of manufacturers.
              As a PA COSTARS member, we have been helping supply PA schools and universities with the tech they need at an affordable price. 
              Contact us today for pricing on projectors, wireless microphones, sound systems, control equipment, audio consoles, 
              projection screens, displays and more.
            </p>
            <a
              href='/assets/files/SLT-COSTARS-034-Flyer.pdf'
              target='BLANK' 
              className='ht-btn--default ht-btn--default--dark-hovers'
            >
              COSTARS Manufacturers
            </a>
            <a
              href="mailto:avsales@sltechnology.net"
              target='BLANK'
              className='ht-btn--default ht-btn--default--dark-hovers'
            >
              CONTACT US
            </a>
          </div>
        </div>
      </div>
    );
  }
}

export default AVPricing;
