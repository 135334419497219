import React, { Component } from 'react';

class AVSales extends Component {
  render() {
    return (
      <div className='col-12 section-space--top--30'>
        <div className='service-grid-item service-grid-item--style2'>
          {/* <div className='service-grid-item__image'> ## image above box
            <div className='service-grid-item__image-wrapper'>
              <img src={`/assets/img/service/serviceShort.jpg`} className='img-fluid' alt='Service Grid' />
            </div>
          </div> */}
          <div name='AV Sales' className='service-grid-item__content'>
            <h3 className='title'>
              <img width={50} alt={'icon'} style={{ paddingRight: '10px' }} src='/assets/img/icons/av-sell.png' />
              AV Equipment Sales
            </h3>
            <p className='subtitle'>
              Having high quality, reliable equipment is essential for your presentation! With access to dozens of top
              AV manufactures including Atlas, BrightSign, Chief, Crown, C2G, Da-Lite, dbx, Epson, JBL, Kramer, LG, NEC,
              Panasonic, Listen, Sharp, SoundCraft, Shure, Samsung, and others, we have the solution you need! As a PA
              COSTARS member, we have been helping supply PA schools and universities with the tech they need at an
              affordable price. Contact us today for special pricing on projectors, wireless microphones, sound systems,
              control equipment, audio consoles, projection screens, displays and more.
            </p>
            <a
              href={`${process.env.PUBLIC_URL}/services/audiovisual/sales`}
              className='ht-btn--default ht-btn--default--dark-hovers'
            >
              Learn More
            </a>
          </div>
        </div>
      </div>
    );
  }
}

export default AVSales;
