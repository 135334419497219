import React, { Component } from 'react';

class SharePoint extends Component {
  render() {
    return (
      <div className='col-12 section-space--top--30'>
        <div className='service-grid-item service-grid-item--style2'>
          {/* <div className='service-grid-item__image'> ## image above box
            <div className='service-grid-item__image-wrapper'>
              <img src={`/assets/img/service/serviceShort.jpg`} className='img-fluid' alt='Service Grid' />
            </div>
          </div> */}
          <div name='SharePoint' className='service-grid-item__content'>
            <h3 className='title'>
              <img
                width={50}
                alt={'icon'}
                style={{ paddingRight: '10px' }}
                src='/assets/img/icons/Microsoft_Office_SharePoint.png'
              />
              SharePoint
            </h3>
            <p className='subtitle'>
              Included with Microsoft 365, SharePoint can store and organize shared documents in the cloud, create a
              company Intranet and provide remote access to files for employees. SharePoint can also be locally hosted
              on a server providing highly configurable solutions for unique business needs.
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default SharePoint;
