import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Sidebar from './smallbusiness/Sidebar';
import ServiceGallery from './smallbusiness/ServiceGallery';
import BrandLogoSlider from '../components/BrandLogoSlider';

// IT Service Modules
import AntivirusSecurityBackup from '../service/components/AntivirusSecurityBackup';
import ComputerRepair from '../service/components/ComputerRepair';
import ComputerSales from '../service/components/ComputerSales';
import InternetVoiceServiceConsulting from '../service/components/InternetVoiceServiceConsulting';
import MicrosoftOffice365 from '../service/components/MicrosoftOffice365';
import Networking from '../service/components/Networking';
import Servers from '../service/components/Servers';
import SharePoint from '../service/components/SharePoint';
import VoiceOverIP from '../service/components/VoiceOverIP';

// AV Service Modules
import AVIntergration from '../service/components/AVIntergration';
import AVSales from '../service/components/AVSales';
import AVRental from '../service/components/AVRental';

class ServiceDetailsLeftSidebar extends Component {
  render() {
    return (
      <div>
        {/* breadcrumb */}
        {/*====================  breadcrumb area ====================*/}
        <div className='breadcrumb-area small-business-bg'>
          <div className='container'>
            <div className='row'>
              <div className='col'>
                <div className='page-banner text-center'>
                  <h1>Small Business</h1>
                  <ul className='page-breadcrumb'>
                    <li>
                      <Link to='/'>Home</Link>
                    </li>
                    <li>
                      <Link to='/solutions'>Solutions For...</Link>
                    </li>
                    <li>Small Business</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*====================  End of breadcrumb area  ====================*/}

        <div className='page-wrapper section-space--inner--120'>
          {/*Service section start*/}
          <div className='service-section'>
            <div className='container'>
              <div className='row'>
                <div className='col-lg-8 col-12 order-1 order-lg-2'>
                  <div className='service-details'>
                    {/* service gallery */}
                    <ServiceGallery />

                    {/*Service section start*/}
                    <div className='service-section'>
                      <div className='row'>
                        <div className='col-lg-12'>
                          <div className='service-item-wrapper'>
                            <div className='row'>
                              <div className='col-12 section-space--top--20'>
                                <h2>Small Business</h2>
                                <p>
                                  As a small business ourselves, we understand the challenges small businesses face and
                                  are here to help technology work for you at a price you can afford. We support small
                                  businesses throughout Lancaster, Berks and Chester counties.
                                </p>
                              </div>
                              <AntivirusSecurityBackup />
                              <ComputerRepair />
                              <ComputerSales />
                              <InternetVoiceServiceConsulting />
                              <MicrosoftOffice365 />
                              <Networking />
                              <Servers />
                              <SharePoint />
                              <VoiceOverIP />
                              <AVIntergration />
                              <AVSales />
                              <AVRental />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/*Service section end*/}
                  </div>
                </div>
                <div className='col-lg-4 col-12 order-2 order-lg-1'>
                  <Sidebar />
                </div>
              </div>
            </div>
          </div>
          {/*Service section end*/}
        </div>
        {/* Brand logo */}
        <BrandLogoSlider background='grey-bg' />
      </div>
    );
  }
}

export default ServiceDetailsLeftSidebar;
