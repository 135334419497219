import React, { Component } from 'react';
import { Link } from 'react-scroll';

class Sidebar extends Component {
  render() {
    let data = [
      {
        sidebarListIcon: 'flaticon-015-cart',
        sidebarListText: 'Antivirus, Security & Backup',
      },
      {
        sidebarListIcon: 'flaticon-010-tank-1',
        sidebarListText: 'Computer Repair',
      },
      {
        sidebarListIcon: 'flaticon-002-welding',
        sidebarListText: 'Computer Sales',
      },
      {
        sidebarListIcon: 'flaticon-004-walkie-talkie',
        sidebarListText: 'Internet & Voice Service Consulting',
      },
      {
        sidebarListIcon: 'flaticon-042-monitor',
        sidebarListText: 'Microsoft 365',
      },
      {
        sidebarListIcon: 'flaticon-050-boxes',
        sidebarListText: 'Networking',
      },
      {
        sidebarListIcon: 'flaticon-042-monitor',
        sidebarListText: 'Servers',
      },
      {
        sidebarListIcon: 'flaticon-050-boxes',
        sidebarListText: 'SharePoint',
      },
      {
        sidebarListIcon: 'flaticon-050-boxes',
        sidebarListText: 'Voice over IP',
      },
    ];

    let Datalist = data.map((val, i) => {
      return (
        <li key={i}>
          <Link to={val.sidebarListText} spy={true} smooth={true} offset={-20} duration={500}>
            <i className={val.sidebarListIcon} />
            {val.sidebarListText}
          </Link>
        </li>
      );
    });

    return (
      <div>
        <div className='sidebar-wrapper'>
          <div className='sidebar'>
            <h3 className='sidebar-title'>IT Support</h3>
            <ul className='sidebar-list'>{Datalist}</ul>
          </div>
          {/* <div className="sidebar">
                        <h3 className="sidebar-title">Download brochure</h3>
                        <ul className="sidebar-list">
                            <li>
                                <Link to="/">
                                    <i className="fa fa-file-pdf-o" />
                                    Brochures.PDF
                                </Link>
                            </li>
                            <li>
                                <Link to="/">
                                    <i className="fa fa-file-word-o" />
                                    Brochures.DOC
                                </Link>
                            </li>
                        </ul>
                    </div> */}
        </div>
      </div>
    );
  }
}

export default Sidebar;
