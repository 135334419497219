import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class ServicesOverview extends Component {
  render() {
    let data = [
      {
        pageLink: 'services/it-support',
        img: 'it-support.jpg',
        iconClass: 'flaticon-technician',
        serviceTitle: 'IT Support',
        serviceSubtitle:
          'We offer flexible support options to keep your technology up and running! From servers, Wi-Fi and phones to printers, workstations and software, we’ll provide the help you need at a price you can afford.',
      },
      {
        pageLink: 'services/audiovisual',
        img: 'av-and-security.jpg',
        iconClass: 'flaticon-av-cable',
        serviceTitle: 'Audiovisual Services',
        serviceSubtitle:
          'SL installs, sells, and rents digital projector systems, sound systems, wireless microphones, and control systems to enhance your presentation space. As a PA COSTARS vendor, we can provide competitive pricing on projectors, microphones, and other presentation equipment.',
      },
      {
        pageLink: 'services/video-surveillance',
        img: 'security.jpg',
        iconClass: 'flaticon-cctv',
        serviceTitle: 'Video Surveillance',
        serviceSubtitle:
          'Physical security is essential for most businesses. We provide and install digital camera systems and recorders of any size from a variety of manufacturers including Axis and Milestone.',
      },
      {
        pageLink: 'services/software-development',
        img: 'software-development.jpg',
        iconClass: 'flaticon-web-programming',
        serviceTitle: 'Software Development',
        serviceSubtitle:
          'We solve unique software challenges by writing custom applications to suit your needs. Our experienced developers can help develop a plan for a custom application and work with you to bring it to fruition.',
      },
    ];
    let Datalist = data.map((val, i) => {
      return (
        <div className='col-lg-3 col-md-6 col-12 section-space--bottom--30' key={i}>
          <Link to={`${process.env.PUBLIC_URL}/${val.pageLink}`}>
            <div className='service-grid-item'>
              <div className='service-grid-item__image'>
                <div className='service-grid-item__image-wrapper'>
                  <img src={`assets/img/service/${val.img}`} className='img-fluid' alt='Service Grid' />
                </div>
                <div className='icon'>
                  <i className={val.iconClass} />
                </div>
              </div>
              <div className='service-grid-item__content'>
                <h3 className='title'>
                  <Link to={`${process.env.PUBLIC_URL}/${val.pageLink}`}>{val.serviceTitle}</Link>
                </h3>
                <p className='subtitle'>{val.serviceSubtitle}</p>
                <Link to={`${process.env.PUBLIC_URL}/${val.pageLink}`} className='see-more-link'>
                  LEARN MORE
                </Link>
              </div>
            </div>
          </Link>
        </div>
      );
    });

    return Datalist;
  }
}

export default ServicesOverview;
