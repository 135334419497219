import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Sidebar from './education/Sidebar';
import ServiceGallery from './education/ServiceGallery';
import BrandLogoSlider from '../components/BrandLogoSlider';

// Costars Service Module
import COSTARSGeneral from '../service/components/COSTARSGeneral';

// IT Service Modules
import InternetVoiceServiceConsulting from '../service/components/InternetVoiceServiceConsulting';
import MicrosoftOffice365 from '../service/components/MicrosoftOffice365';
import SharePoint from '../service/components/SharePoint';

// AV Service Modules
import AVIntergration from '../service/components/AVIntergration';
import AVSales from '../service/components/AVSales';

// Software Development Service Modules
import IdentityManagementSolutions from '../service/components/IdentityManagementSolutions';
import SharepointIntegrationServices from '../service/components/SharePointIntegrationServices';

class ServiceDetailsLeftSidebar extends Component {
  render() {
    return (
      <div>
        {/* breadcrumb */}
        {/*====================  breadcrumb area ====================*/}
        <div className='breadcrumb-area education-bg'>
          <div className='container'>
            <div className='row'>
              <div className='col'>
                <div className='page-banner text-center'>
                  <h1>Education</h1>
                  <ul className='page-breadcrumb'>
                    <li>
                      <Link to='/'>Home</Link>
                    </li>
                    <li>
                      <Link to='/solutions'>Solutions For...</Link>
                    </li>
                    <li>Education</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*====================  End of breadcrumb area  ====================*/}

        <div className='page-wrapper section-space--inner--120'>
          {/*Service section start*/}
          <div className='service-section'>
            <div className='container'>
              <div className='row'>
                <div className='col-lg-8 col-12 order-1 order-lg-2'>
                  <div className='service-details'>
                    {/* service gallery */}
                    <ServiceGallery />

                    {/*Service section start*/}
                    <div className='service-section'>
                      <div className='row'>
                        <div className='col-lg-12'>
                          <div className='service-item-wrapper'>
                            <div className='row'>
                              <div className='col-12 section-space--top--20'>
                                <h2>Education</h2>
                                <p>
                                  With over a decade of education technology experience we provide user management
                                  solutions using Microsoft Identity Manger and Forefront Identity Manager to integrate
                                  with your software systems, custom application development services, SharePoint
                                  integration as well as hardware solutions including audiovisual and physical security
                                  on PA COSTARS contracts.
                                </p>
                              </div>
                              <COSTARSGeneral />
                              <AVIntergration />
                              <AVSales />

                              <InternetVoiceServiceConsulting />
                              <MicrosoftOffice365 />
                              <SharePoint />

                              <IdentityManagementSolutions />
                              <SharepointIntegrationServices />

                              {/* software dev modules */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/*Service section end*/}
                  </div>
                </div>
                <div className='col-lg-4 col-12 order-2 order-lg-1'>
                  <Sidebar />
                </div>
              </div>
            </div>
          </div>
          {/*Service section end*/}
        </div>
        {/* Brand logo */}
        <BrandLogoSlider background='grey-bg' />
      </div>
    );
  }
}

export default ServiceDetailsLeftSidebar;
