import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Sidebar from './audiovisual/Sidebar';
import ServiceGallery from './audiovisual/ServiceGallery';
import BrandLogoSlider from '../components/BrandLogoSlider';

// Service Modules
import AVIntergration from './components/AVIntergration';
import AVSales from './components/AVSales';
import AVRental from './components/AVRental';
import COSTARSAV from './components/COSTARSAV';

class ServiceDetailsLeftSidebar extends Component {
  render() {
    return (
      <div>
        {/* breadcrumb */}
        {/*====================  breadcrumb area ====================*/}
        <div className='breadcrumb-area audiovisual-bg'>
          <div className='container'>
            <div className='row'>
              <div className='col'>
                <div className='page-banner text-center'>
                  <h1>Audiovisual Services</h1>
                  <ul className='page-breadcrumb'>
                    <li>
                      <Link to='/'>Home</Link>
                    </li>
                    <li>
                      <Link to={`${process.env.PUBLIC_URL}/services`}>Services</Link>
                    </li>
                    <li>Audiovisual</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*====================  End of breadcrumb area  ====================*/}

        <div className='page-wrapper section-space--inner--120'>
          {/*Service section start*/}
          <div className='service-section'>
            <div className='container'>
              <div className='row'>
                <div className='col-lg-8 col-12 order-1 order-lg-2'>
                  <div className='service-details'>
                    {/* service gallery */}
                    <ServiceGallery />

                    <div className='content section-space--top--30'>
                      <div className='row'>
                        <div className='col-12'>
                          <h2>Audiovisual Services</h2>
                          <p>
                            SL provides a variety of audio visual installation, sales and rental services. AV has come a
                            long way in the last few years and we’re here to help outfit meeting spaces, churches, or
                            classrooms with equipment that works for you! We work with a wide range of manufactures
                            offering top quality equipment including NEC laser projectors and audio solutions from JBL,
                            Shure and Atlas. We participate in COSTARS and offer special contract pricing for
                            participating government agencies.
                          </p>
                        </div>
                        <COSTARSAV />
                        <AVIntergration />
                        <AVSales />
                        <AVRental />
                      </div>
                    </div>
                  </div>
                </div>
                <div className='col-lg-4 col-12 order-2 order-lg-1'>
                  <Sidebar />
                </div>
              </div>
            </div>
          </div>
          {/*Service section end*/}
        </div>

        {/* Brand logo */}
        <BrandLogoSlider background='grey-bg' />
      </div>
    );
  }
}

export default ServiceDetailsLeftSidebar;
