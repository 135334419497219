import React, { Component } from 'react';

class ComputerRepair extends Component {
  render() {
    return (
      <div className='col-12 section-space--top--30'>
        <div className='service-grid-item service-grid-item--style2'>
          {/* <div className='service-grid-item__image'> ## image above box
            <div className='service-grid-item__image-wrapper'>
              <img src={`/assets/img/service/serviceShort.jpg`} className='img-fluid' alt='Service Grid' />
            </div>
          </div> */}
          <div name='Computer Repair' className='service-grid-item__content'>
            <h3 className='title'>
              <img
                width={50}
                alt={'icon'}
                style={{ paddingRight: '10px' }}
                src='/assets/img/icons/ComputerRepair.png'
              />
              Computer Repair
            </h3>
            <p className='subtitle'>
              SL provides computer repair services at your location or ours. From hard drive upgrades, solid state drive
              installations to custom PC’s, we’ll assess and troubleshoot issues and make appropriate recommendations
              for repair or replacement of your desktop, laptop or server.
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default ComputerRepair;
