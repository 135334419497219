import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Sidebar from './audiovisual/audiovisualrental/Sidebar';
import ServiceGallery from './audiovisual/audiovisualrental/ServiceGallery';
import BrandLogoSlider from '../components/BrandLogoSlider';

// Service Modules
import PoweredSpeakers from './components/AVRentalEquipment/PoweredSpeakers';
import MicrophoneSystems from './components/AVRentalEquipment/MicrophoneSystems';
import Projectors from './components/AVRentalEquipment/Projectors';
import Cabling from './components/AVRentalEquipment/Cabling';

class ServiceDetailsLeftSidebar extends Component {
  render() {
    return (
      <div>
        {/* breadcrumb */}
        {/*====================  breadcrumb area ====================*/}
        <div className='breadcrumb-area avrental-bg'>
          <div className='container'>
            <div className='row'>
              <div className='col'>
                <div className='page-banner text-center'>
                  <h1>AV Equipment Rental</h1>
                  <ul className='page-breadcrumb'>
                    <li>
                      <Link to='/'>Home</Link>
                    </li>
                    <li>
                      <Link to={`${process.env.PUBLIC_URL}/services`}>Services</Link>
                    </li>
                    <li>
                      <Link to={`${process.env.PUBLIC_URL}/services/audiovisual`}>Audiovisual</Link>
                    </li>
                    <li>Rental</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*====================  End of breadcrumb area  ====================*/}

        <div className='page-wrapper section-space--inner--120'>
          {/*Service section start*/}
          <div className='service-section'>
            <div className='container'>
              <div className='row'>
                <div className='col-lg-8 col-12 order-1 order-lg-2'>
                  <div className='service-details'>
                    {/* service gallery */}
                    <ServiceGallery />

                    <div className='content section-space--top--30'>
                      <div className='row'>
                        <div className='col-12'>
                          <h2>AV Equipment Rental</h2>
                          <p>
                            Looking for AV equipment for your next event or show? We stock a limited amount of rental AV equipment 
                            at our New Holland location including powered speakers, wireless microphone systems, projectors, and cabling. <a href={`${process.env.PUBLIC_URL}/contact-us`}>Contact us</a> for availability and pricing.

                          </p>
                        </div>
                      </div>
                    </div>
                    <PoweredSpeakers />
                    <MicrophoneSystems />
                    <Projectors />
                    <Cabling />
                  </div>
                </div>
                <div className='col-lg-4 col-12 order-2 order-lg-1'>
                  <Sidebar />
                </div>
              </div>
            </div>
          </div>
          {/*Service section end*/}
        </div>

        {/* Brand logo */}
        <BrandLogoSlider background='grey-bg' />
      </div>
    );
  }
}

export default ServiceDetailsLeftSidebar;
