import React, { Component } from 'react';

class VoiceOverIP extends Component {
  render() {
    return (
      <div className='col-12 section-space--top--30'>
        <div className='service-grid-item service-grid-item--style2'>
          {/* <div className='service-grid-item__image'> ## image above box
            <div className='service-grid-item__image-wrapper'>
              <img src={`/assets/img/service/serviceShort.jpg`} className='img-fluid' alt='Service Grid' />
            </div>
          </div> */}
          <div name='Voice over IP' className='service-grid-item__content'>
            <h3 className='title'>
              <img width={50} alt={'icon'} style={{ paddingRight: '10px' }} src='/assets/img/icons/voip.png' />
              Voice over IP
            </h3>
            <p className='subtitle'>
              Modern communication solutions of any size can be tailored to enhance connectivity remotely, provide
              crystal clear audio, send voicemails to your email and reduce your monthly phone bills. We install locally
              hosted phone systems from Digium and Sangoma as well as cloud based solutions perfect for business with
              multiple locations or with employees that work from home.
            </p>
            <a
              href='https://www.sangoma.com/ip-phones/'
              target='BLANK'
              className='ht-btn--default ht-btn--default--dark-hovers'
            >
              Installed Phone Systems
            </a>
            <a
              href='https://www.sangoma.com/business-phone-systems/hosted-pbx/'
              target='BLANK'
              className='ht-btn--default ht-btn--default--dark-hovers'
            >
              Cloud Phone Systems from Sangoma
            </a>
            <a href='https://www.8x8.com/' target='BLANK' className='ht-btn--default ht-btn--default--dark-hovers'>
              Cloud Phone Systems from 8x8
            </a>
          </div>
        </div>
      </div>
    );
  }
}

export default VoiceOverIP;
