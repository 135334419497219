import React, { Component } from 'react';

class RecordingSystems extends Component {
  render() {
    return (
      <div className='col-12 section-space--top--30'>
        <div className='service-grid-item service-grid-item--style2'>
          {/* <div className='service-grid-item__image'> ## image above box
            <div className='service-grid-item__image-wrapper'>
              <img src={`/assets/img/service/serviceShort.jpg`} className='img-fluid' alt='Service Grid' />
            </div>
          </div> */}
          <div name='Milestone' className='service-grid-item__content'>
            <h3 className='title'>
              <img width={50} alt={'icon'} style={{ paddingRight: '10px' }} src='/assets/img/icons/milestone.png' />
              Milestone
            </h3>
            <p className='subtitle'>
              Milestone xProtect offers ultimate flexibility and usability in an affordable and easy to use software
              solution. From 8 cameras to 800 we can design a solution to fit your needs. Need Milestone Care or
              additional device licenses? Contact us for special pricing. We offer Milestone on PA COSTARS for PA
              schools, municipalities and other government organizations.
            </p>
            <a
              href='/assets/files/XProtect VMS Brochure.pdf'
              target='BLANK'
              className='ht-btn--default ht-btn--default--dark-hovers'
            >
              Milestone XProtect Brochure
            </a>
            <a
              href='https://www.milestonesys.com/'
              target='BLANK'
              className='ht-btn--default ht-btn--default--dark-hovers'
            >
              Milestone Systems
            </a>
          </div>
        </div>
      </div>
    );
  }
}

export default RecordingSystems;
