import React, { Component } from 'react';

class AVRental extends Component {
  render() {
    return (
      <div className='col-12 section-space--top--30'>
        <div className='service-grid-item service-grid-item--style2'>
          {/* <div className='service-grid-item__image'> ## image above box
            <div className='service-grid-item__image-wrapper'>
              <img src={`/assets/img/service/serviceShort.jpg`} className='img-fluid' alt='Service Grid' />
            </div>
          </div> */}
          <div name='AV Rental' className='service-grid-item__content'>
            <h3 className='title'>
              <img width={50} alt={'icon'} style={{ paddingRight: '10px' }} src='/assets/img/icons/rent.png' />
              AV Equipment Rental
            </h3>
            <p className='subtitle'>
              We have a limited amount of rental AV equipment at our New Holland, PA location. Available gear includes
              powered speakers, wireless microphones, projectors, and cabling. Renting AV equipment from SL Technology is 
              an easy and affordable option for your next event, show, or presentation.
            </p>
            <a
              href={`${process.env.PUBLIC_URL}/services/audiovisual/rental`}
              target=''
              className='ht-btn--default ht-btn--default--dark-hovers'
            >
              LEARN MORE
            </a>
          </div>
        </div>
      </div>
    );
  }
}

export default AVRental;
