import React, { Component } from 'react';
import Swiper from 'react-id-swiper';

class HeroSliderOne extends Component {
  render() {
    const params = {
      slidesPerView: 1,
      loop: true,
      noSwiping: false,
      speed: 500,
      watchSlidesVisibility: true,
      effect: 'fade',
    };

    let data = [
      {
        bgImg: '1-home.jpg',
        sliderTitle: 'Welcome to\nSL Technology',
        sliderSubtitle:
          'Established in 2008, SL Technology has been serving the unique technology needs of businesses, schools, churches, and individuals of Lancaster County and beyond for over a decade.',
        btnLink: 'about-us',
      },
    ];

    let DataList = data.map((val, i) => {
      return (
        <div className='swiper-slide' key={i}>
          <div className='hero-slider__single-item' style={{ backgroundImage: `url(assets/img/slider/${val.bgImg})` }}>
            <div className='hero-slider__content-wrapper'>
              <div className='container'>
                <div className='row'>
                  <div className='col-lg-12'>
                    <div className='hero-slider__content'>
                      <h2 className='hero-slider__title'>{val.sliderTitle}</h2>
                      <p className='hero-slider__text'>{val.sliderSubtitle}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    });

    return (
      <div>
        {/*====================  hero slider area ====================*/}
        <div className='hero-alider-area'>
          <Swiper {...params}>{DataList}</Swiper>
        </div>
        {/*====================  End of hero slider area  ====================*/}
      </div>
    );
  }
}

export default HeroSliderOne;
