import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Sidebar from './audiovisual/audiovisualintegration/Sidebar';
import ServiceGallery from './audiovisual/ServiceGallery';
import BrandLogoSlider from '../components/BrandLogoSlider';

// Service Modules
import AVEducation from './components/AVIntegration/AVEducation';
import AVMeeting from './components/AVIntegration/AVMeeting';
import AVRetail from './components/AVIntegration/AVRetail';
import AVWorship from './components/AVIntegration/AVWorship';

class ServiceDetailsLeftSidebar extends Component {
  render() {
    return (
      <div>
        {/* breadcrumb */}
        {/*====================  breadcrumb area ====================*/}
        <div className='breadcrumb-area avintegration-bg'>
          <div className='container'>
            <div className='row'>
              <div className='col'>
                <div className='page-banner text-center'>
                  <h1>AV Integration</h1>
                  <ul className='page-breadcrumb'>
                    <li>
                      <Link to='/'>Home</Link>
                    </li>
                    <li>
                      <Link to={`${process.env.PUBLIC_URL}/services`}>Services</Link>
                    </li>
                    <li>
                      <Link to={`${process.env.PUBLIC_URL}/services/audiovisual`}>Audiovisual</Link>
                    </li>
                    <li>Integration</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*====================  End of breadcrumb area  ====================*/}

        <div className='page-wrapper section-space--inner--120'>
          {/*Service section start*/}
          <div className='service-section'>
            <div className='container'>
              <div className='row'>
                <div className='col-lg-8 col-12 order-1 order-lg-2'>
                  <div className='service-details'>
                    {/* service gallery */}
                    <ServiceGallery />

                    <div className='content section-space--top--30'>
                      <div className='row'>
                        <div className='col-12'>
                          <h2>AV Integration</h2>
                          <p>
                          SL designs and installs audiovisual systems for small to medium-sized businesses.
                          From installing distributed sound systens to integrating projection, audio, and lighting into 
                          simple to use interfaces, we'll work with you to create an AV solution that fits
                          your needs. <Link to={`${process.env.PUBLIC_URL}/contact-us`}>Contact us</Link> to discuss 
                          details on your specific needs, budget, and use case.
                          </p>
                        </div>
                        <AVEducation />
                        <AVMeeting />
                        <AVRetail />
                        <AVWorship />
                      </div>
                    </div>
                  </div>
                </div>
                <div className='col-lg-4 col-12 order-2 order-lg-1'>
                  <Sidebar />
                </div>
              </div>
            </div>
          </div>
          {/*Service section end*/}
        </div>

        {/* Brand logo */}
        <BrandLogoSlider background='grey-bg' />
      </div>
    );
  }
}

export default ServiceDetailsLeftSidebar;
