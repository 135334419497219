import React, { Component } from 'react';

class AVIntegration extends Component {
  render() {
    return (
      <div className='col-12 section-space--top--30'>
        <div className='service-grid-item service-grid-item--style2'>
          {/* <div className='service-grid-item__image'> ## image above box
            <div className='service-grid-item__image-wrapper'>
              <img src={`/assets/img/service/serviceShort.jpg`} className='img-fluid' alt='Service Grid' />
            </div>
          </div> */}
          <div name='AV Integration' className='service-grid-item__content'>
            <h3 className='title'>
              <img width={50} alt={'icon'} style={{ paddingRight: '10px' }} src='/assets/img/icons/AVIntegration.png' />
              AV Integration
            </h3>
            <p className='subtitle'>
              We design, sell, install small to large sized audiovisual systems. We specialize in integrating projection, audio, and
              lighting into simple to use interfaces or button panels to fit your needs. Contact us to discuss details
              on your specific needs, budget, and use case.
            </p>
            <a
              href={`${process.env.PUBLIC_URL}/services/audiovisual/integration`}
              target=''
              className='ht-btn--default ht-btn--default--dark-hovers'
            >
              LEARN MORE
            </a>
          </div>
        </div>
      </div>
    );
  }
}

export default AVIntegration;
