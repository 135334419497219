import React, { Component } from 'react';

class Downloads extends Component {
  render() {
    const { match } = this.props; // coming from React Router.

    var exists = true;
    var file = match.params.file;

    if (file === 'TeamViewer QS') {
      window.location.href = '/assets/files/TeamViewerQS.exe';
    } else if (file === 'TeamViewer Host') {
      window.location.href = '/assets/files/TeamViewer_Host_Setup.exe';
    } else if (file === 'SL Software') {
      window.location.href =
        'https://firebasestorage.googleapis.com/v0/b/sl-technology.appspot.com/o/SL%20Software.ppkg?alt=media&token=32170cf8-dd2d-43f8-bda3-a5ba2f1d3165';
    } else {
      exists = false;
    }

    if (exists) {
      window.Arrow.show(90000);
    }

    return (
      <div>
        {/*====================  Download page content ====================*/}
        <div>
          <div className='error-page-wrapper d-flex section-space--inner--120'>
            <div className='container'>
              <div className='row'>
                <div className='col-lg-6 col-md-9 m-auto text-center'>
                  <div className='error-content-centered d-flex align-items-center justfy-content-center'>
                    <div className='error-page-content-wrap'>
                      {exists ? <h3>{file} is now downloading...</h3> : <h3>File not found.</h3>}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Downloads;
