import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class MobileMenu extends Component {
  constructor() {
    super();
    this.state = {
      menuOpen: false,
    };
  }

  changeState = () => {
    if (this.state.menuOpen) {
      this.setState({
        menuOpen: false,
      });
    } else {
      this.setState({
        menuOpen: true,
      });
    }
  };

  render() {
    return (
      <div>
        {/*=======  offcanvas mobile menu  =======*/}
        <div
          className={this.state.menuOpen ? 'offcanvas-mobile-menu active' : 'offcanvas-mobile-menu'}
          id='mobile-menu-overlay'
        >
          <a href='#/' onClick={this.changeState} className='offcanvas-menu-close' id='mobile-menu-close-trigger'>
            <i className='ion-android-close' />
          </a>
          <div className='offcanvas-wrapper'>
            <div className='offcanvas-inner-content'>
              {/* <div className="offcanvas-mobile-search-area">
                                <form action="#">
                                    <input type="search" placeholder="Search ..." />
                                    <button type="submit">
                                        <i className="fa fa-search" />
                                    </button>
                                </form>
                            </div> */}
              <nav className='offcanvas-navigation'>
                <ul>
                  <li>
                    <Link onClick={this.changeState} to={`${process.env.PUBLIC_URL}/`}>
                      Home
                    </Link>
                  </li>
                  <li className='menu-item-has-children'>
                    <Link onClick={this.changeState} to={`${process.env.PUBLIC_URL}/services`}>
                      Services
                    </Link>
                    <ul className='sub-menu'>
                      <li>
                        <Link onClick={this.changeState} to={`${process.env.PUBLIC_URL}/services/it-support`}>
                          IT Support
                        </Link>
                      </li>
                      <li>
                        <Link onClick={this.changeState} to={`${process.env.PUBLIC_URL}/services/audiovisual`}>
                          Audiovisual Services
                        </Link>
                      </li>
                      <li>
                        <Link onClick={this.changeState} to={`${process.env.PUBLIC_URL}/services/video-surveillance`}>
                          Video Surveillance
                        </Link>
                      </li>
                      <li>
                        <Link onClick={this.changeState} to={`${process.env.PUBLIC_URL}/services/software-development`}>
                          Software Development
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li className='menu-item-has-children'>
                    <Link onClick={this.changeState} to={`${process.env.PUBLIC_URL}/services`}>
                      Solutions For...
                    </Link>
                    <ul className='sub-menu'>
                      <li>
                        <Link onClick={this.changeState} to={`${process.env.PUBLIC_URL}/solutions/small-business`}>
                          Small Business
                        </Link>
                      </li>
                      <li>
                        <Link onClick={this.changeState} to={`${process.env.PUBLIC_URL}/solutions/education`}>
                          Education
                        </Link>
                      </li>
                      <li>
                        <Link onClick={this.changeState} to={`${process.env.PUBLIC_URL}/solutions/worship`}>
                          House of Worship
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <Link onClick={this.changeState} to={`${process.env.PUBLIC_URL}/about-us`}>
                      About Us
                    </Link>
                  </li>
                  <li>
                    <Link onClick={this.changeState} to={`${process.env.PUBLIC_URL}/contact-us`}>
                      Contact Us
                    </Link>
                  </li>
                </ul>
              </nav>
              <div className='offcanvas-widget-area'>
                <div className='off-canvas-contact-widget'>
                  <div className='header-contact-info'>
                    <ul className='header-contact-info__list'>
                      <li>
                        <i className='ion-android-phone-portrait' /> <a href='tel://12452456012'>(717) 629-0662</a>
                      </li>
                      <li>
                        <i className='ion-android-mail' />{' '}
                        <a href='mailto:info@sltechnology.net'>info@sltechnology.net</a>
                      </li>
                    </ul>
                  </div>
                </div>
                {/*Off Canvas Widget Social Start*/}
                {/* <div className="off-canvas-widget-social">
                                    <a href="#/" title="Facebook">
                                        <i className="fa fa-facebook" />
                                    </a>
                                    <a href="#/" title="Twitter">
                                        <i className="fa fa-twitter" />
                                    </a>
                                    <a href="#/" title="LinkedIn">
                                        <i className="fa fa-linkedin" />
                                    </a>
                                    <a href="#/" title="Youtube">
                                        <i className="fa fa-youtube-play" />
                                    </a>
                                    <a href="#/" title="Vimeo">
                                        <i className="fa fa-vimeo-square" />
                                    </a>
                                </div> */}
                {/*Off Canvas Widget Social End*/}
              </div>
            </div>
          </div>
        </div>
        {/*=======  End of offcanvas mobile menu  =======*/}
      </div>
    );
  }
}

export default MobileMenu;
