import React, { Component } from 'react';

class Cabling extends Component {
  render() {
    return (
      <div className='col-12 section-space--top--30'>
        <div className='service-grid-item service-grid-item--style2'>
          {/* <div className='service-grid-item__image'> ## image above box
            <div className='service-grid-item__image-wrapper'>
              <img src={`/assets/img/service/serviceShort.jpg`} className='img-fluid' alt='Service Grid' />
            </div>
          </div> */}
          <div name='Cabling' className='service-grid-item__content'>
            <h3 className='title'>
              <img width={50} alt={'icon'} style={{ paddingRight: '10px' }} src='/assets/img/icons/audio-jack.png' />
              Cabling
            </h3>
            <p className='subtitle'>
              Cables are an essential part of most audiovisual systems. We can rent you various cables for your event or show including XLR, CAT, HDMI, AUX, 1/4", Power, and more.
            </p>
            {/* <a
              href={`${process.env.PUBLIC_URL}/contact-us`}
              className='ht-btn--default ht-btn--default--dark-hovers'
            >
              CONTACT US
            </a> */}
          </div>
        </div>
      </div>
    );
  }
}

export default Cabling;
