import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class COSTARS extends Component {
  render() {
    return (
      <div className='col-12 section-space--top--30'>
        <div className='service-grid-item service-grid-item--style2'>
          {/* <div className='service-grid-item__image'> ## image above box
            <div className='service-grid-item__image-wrapper'>
              <img src={`/assets/img/service/serviceShort.jpg`} className='img-fluid' alt='Service Grid' />
            </div>
          </div> */}
          <div name='COSTARS' className='service-grid-item__content'>
            <h3 className='title'>
              <img width={50} alt={'icon'} style={{ paddingRight: '10px' }} src='/assets/img/icons/costars.png' />
              COSTARS
            </h3>
            <p className='subtitle'>
              Many of our security and surveillance products are available on PA COSTARS. <br />
              Contract:<b> 040-E22-127</b>
              <br />
              <Link to={`${process.env.PUBLIC_URL}/contact-us`} className='see-more-link'>
                Contact us for special pricing.
              </Link>
            </p>
            <a
              href='/assets/files/2023-COSTARS-Flyer.pdf'
              target='BLANK'
              className='ht-btn--default ht-btn--default--dark-hovers'
            >
              Download our Brochure
            </a>
            
            <a
              href='https://www.costars.state.pa.us/SearchVendorContract.aspx'
              target='BLANK'
              className='ht-btn--default ht-btn--default--dark-hovers'
            >
              PA COSTARS website
            </a>
          </div>
        </div>
      </div>
    );
  }
}

export default COSTARS;
