import React, { Component } from 'react';

class InternetVoiceServiceConsulting extends Component {
  render() {
    return (
      <div className='col-12 section-space--top--30'>
        <div className='service-grid-item service-grid-item--style2'>
          {/* <div className='service-grid-item__image'> ## image above box
            <div className='service-grid-item__image-wrapper'>
              <img src={`/assets/img/service/serviceShort.jpg`} className='img-fluid' alt='Service Grid' />
            </div>
          </div> */}
          <div name='Internet & Voice Service Consulting' className='service-grid-item__content'>
            <h3 className='title'>
              <img
                width={50}
                alt={'icon'}
                style={{ paddingRight: '10px' }}
                src='/assets/img/icons/wireless-router.png'
              />
              Internet & Voice Service Consulting
            </h3>
            <p className='subtitle'>
              Are you paying too much for internet or phone service? We’ll analyze your usage, infrastructure and
              invoices from your current providers to make sure you’re on the right package and paying the right price.
            </p>
            <p className='subtitle'>
              Looking for new connections? We provide contracts for service with Comcast Business, AT&T, and more without
              all the hassle.
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default InternetVoiceServiceConsulting;
